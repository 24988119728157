import { DatePipe } from '@angular/common';
import {
    Component,
    OnInit,
    ViewEncapsulation,
    ChangeDetectionStrategy,
    Input,
    Output,
    EventEmitter,
    SecurityContext,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { dayInMs } from '../../shared/types/constants.types';
import { safeTagsReplace, URL_MATCH } from '../../shared/utils/safe-html.utils';
import { Message, MessageReadStatus } from '../messaging.types';

@Component({
    selector: 'scp-message-item',
    templateUrl: './message-item.component.html',
    styleUrls: ['./message-item.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageItemComponent implements OnInit {
    @Input() set message(msg: Message) {
        this.msg = msg;
        this.parseMessageText(msg?.text);
    }
    @Input() readStatus: MessageReadStatus = 'hidden';
    @Input() isCurrentUserMessage = false;
    @Input() markable = false;
    @Input() currentUser: Message['author'];
    @Output() markAsRead = new EventEmitter<Message>();

    public isRead = false;
    public richMessageText: SafeHtml = null;
    public readStatusTooltip = '';
    public resolveCheckboxTooltip = '';

    get message() {
        return this.msg;
    }

    private msg: Message;

    constructor(private sanitazer: DomSanitizer, private datePipe: DatePipe) {}

    public ngOnInit(): void {
        this.isRead = this.message && !!this.message.isReadByUser;

        if (this.isRead) {
            const formattedDate = this.datePipe.transform(
                this.message.isReadByOtherPartyDatetime,
                'dd. MMM y, HH:mm:ss',
            );
            this.readStatusTooltip = `Seen by ${this.message.isReadByUser?.nickname} at ${formattedDate}`;
        } else {
            this.readStatusTooltip = `Not seen yet`;
        }

        if (this.markable) {
            const formattedDate = this.isRead
                ? this.datePipe.transform(this.message.isReadByOtherPartyDatetime, 'dd. MMM y, HH:mm:ss')
                : '';
            this.resolveCheckboxTooltip = this.isRead
                ? `Resolved by ${this.message.isReadByUser?.nickname} at ${formattedDate}\nUncheck, if this message requires further action.`
                : 'Mark as resolved, if this message requires no further action';
        }
    }

    public getDateFormat(dateStr: string): string {
        const now = Date.now();
        const date = new Date(dateStr);

        // today (last 24h)
        if (Math.abs(now - date.getTime()) < dayInMs) {
            return 'HH:mm';
        }

        return 'dd. MMM y';
    }

    public changeReadStatus(): void {
        if (this.isRead) {
            this.message.isReadByUser = this.currentUser;
        } else {
            this.message.isReadByUser = null;
        }
        this.markAsRead.emit(this.message);
    }

    private parseMessageText(msgText: string): void {
        if (!msgText) {
            return;
        }
        const safeText = safeTagsReplace(msgText);
        let sanitazedText = this.sanitazer.sanitize(SecurityContext.HTML, safeText);
        const replaceString = /&#10;/gi;
        sanitazedText = sanitazedText.replace(replaceString,'\n');
        sanitazedText = sanitazedText.replace(URL_MATCH, url => `<a href="${url}" target="_blank">${url}</a>`);
        this.richMessageText = this.sanitazer.bypassSecurityTrustHtml(sanitazedText);
    }
}
