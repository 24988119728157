<div class="main">
    <div class="element">
        <div>
            <h1>Whooops</h1>
        </div>
        <div>
            <h1>404</h1>
        </div>
        <button mat-raised-button color="primary" (click)="goToMainPage()">Go to main page</button>
    </div>
</div>
