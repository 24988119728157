<cdk-virtual-scroll-viewport itemSize="48">
    <table>
        <thead>
            <tr>
                <th *ngFor="let column of columns" [style.width]="column.size">
                    {{ column.title }}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
                *cdkVirtualFor="let row of rows; trackBy: trackBy"
                [ngClass]="extras?.getClass && extras.getClass(row)"
                [routerLink]="extras?.getLink && extras.getLink(row)"
            >
                <td *ngFor="let column of columns" [style.width]="column.size">
                    <ng-template
                        [ngTemplateOutlet]="cellTemplates[column.property]"
                        [ngTemplateOutletContext]="{ row: row }"
                    ></ng-template>
                </td>
            </tr>
        </tbody>
    </table>
</cdk-virtual-scroll-viewport>

<ng-content></ng-content>
