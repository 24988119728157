import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    Input,
    OnInit,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'scp-ui-cell',
    templateUrl: 'cell.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellComponent implements OnInit {
    @Input() property: string;
    @ContentChild(TemplateRef) template: TemplateRef<any>;

    constructor() {}

    ngOnInit() {}
}
