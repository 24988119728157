import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'scp-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.sass'],
})
export class PageNotFoundComponent {
    constructor(private router: Router) {}

    public goToMainPage(): void {
        this.router.navigate(['']);
    }
}
