<mat-toolbar color="primary" *ngIf="hasToolbar">
    <ng-content select="[header]"></ng-content>
    <scp-ui-icon additional-buttons class="no-padding" (click)="close.emit()" [name]="'close'"></scp-ui-icon>
</mat-toolbar>
<scp-ui-content class="no-padding scroll-over-80vh">
    <ng-content select="[body]"></ng-content>
</scp-ui-content>
<footer>
    <ng-content select="[footer]"></ng-content>
</footer>
