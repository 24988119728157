import { DEFAULT_CURRENCY_CODE, Inject, LOCALE_ID, Pipe } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
    name: 'currencyCents',
})
export class CurrencyCentsPipe extends CurrencyPipe {
    transform(
        value: number | string,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    transform(
        value: null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    transform(value: number, ...rest: any[]): string {
        return super.transform(value / 100, ...rest);
    }
}
