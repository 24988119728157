import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-ui-layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['./layout.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
