<div [ngSwitch]="type" (click)="onClick($event)">
    <button *ngSwitchCase="'raised'" mat-raised-button [color]="color" [disabled]="disabled || inProgress">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>

    <button *ngSwitchCase="'stroked'" mat-stroked-button [color]="color" [disabled]="disabled || inProgress">
        <ng-template [ngTemplateOutlet]="content"></ng-template>
    </button>
</div>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>

<scp-ui-spinner *ngIf="inProgress" class="centered small"></scp-ui-spinner>
