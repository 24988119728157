import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-user-icon',
    templateUrl: 'user-icon.component.html',
    styleUrls: ['./user-icon.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserIconComponent {
    constructor() {}
}
