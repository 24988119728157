<div class="conversation-head">
    <div class="conversation-name">
        {{ isCurrentUserMessage ? 'You' : (message.author ? message.author.nickname : 'stylers.cloud') }}
    </div>
    <p
        class="chat-time"
        [attr.title]="message.createdDatetime|date:'dd. MMM y, HH:mm:ss'"
    >
        <i class="chat-time-icon"></i>
        <span>{{ message.createdDatetime | date:getDateFormat(message.createdDatetime) }}</span>
    </p>
    <span
        *ngIf="readStatus !== 'hidden'"
        class="conversetion-read-status gray-text"
        [class.d-none]="!isCurrentUserMessage"
        [attr.title]="readStatusTooltip"
    >
        {{ isRead ? 'seen' : 'not seen yet' }}
    </span>
</div>
<div
    class="text-bubble"
    [class.resolved]="markable && !isCurrentUserMessage && isRead"
    [innerHtml]="richMessageText"
></div>
<label
    *ngIf="markable && !isCurrentUserMessage"
    [attr.for]="'resolve-' + message.id"
    [attr.title]="resolveCheckboxTooltip"
>
    <input
        [attr.id]="'resolve-' + message.id"
        type="checkbox"
        [(ngModel)]="isRead"
        (change)="changeReadStatus()"
    > resolved
</label>
