<div>
    <scp-ui-button *ngIf="pagination.pageNumber > 1" (click)="onPrevPageBtnClick()"><- Prev</scp-ui-button>
</div>

<div> {{ pagination.pageNumber }} / {{ getMaxPages() }} </div>

<div>
    <scp-ui-button
        *ngIf="pagination.pageSize * pagination.pageNumber < pagination.totalCount"
        (click)="onNextPageBtnClick()"
        >Next -></scp-ui-button
    >
</div>
