import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { StateIconComponent } from './state-icon/state-icon.component';
import { UserIconComponent } from './user-icon/user-icon.component';
import { EnvComponent } from './env/env.component';
import { GenericAlignAndValignAndBlockComponent } from './layout/generic-align-and-valign';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HeaderComponent } from './header/header.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ContentComponent } from './content/content.component';
import { IconComponent } from './ui-icon/icon.component';
import { MatIconModule } from '@angular/material/icon';
import { FlexContainerComponent } from './flex-container/flex-container.component';
import { BoxComponent } from './box/box.component';
import { ButtonComponent } from './button/button.component';
import { MatButtonModule } from '@angular/material/button';
import { SpinnerComponent } from './spinner/spinner.component';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component';
import { TableComponent } from './table/table.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CellComponent } from './table/cell/cell.component';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './table/pagination/pagination.component';
import { BadgePillComponent } from './badge-pill/badge-pill.component';
import { DropdownOverlayComponent } from './dropdown-overlay/dropdown-overlay.component';
import { TextComponent } from './text/text.component';
import { LayoutComponent } from './layout/layout.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PageFooterComponent } from './page-footer/page-footer.component';
import { PageContentComponent } from './page-content/page-content.component';
import { ConfirmWindowComponent } from './confirm-window/confirm-window.component';
import { BrowserInfoComponent } from './browser-info/browser-info.component';
import { SiteSwitchComponent } from './site-switch/site-switch.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UiCommonModule } from '../common/ui-common.module';

const components = [
    HeaderComponent,
    ContentComponent,
    FlexContainerComponent,
    BoxComponent,
    ButtonComponent,
    IconComponent,
    UserIconComponent,
    StateIconComponent,
    SpinnerComponent,
    ModalComponent,
    DropdownComponent,
    DropdownItemComponent,
    TableComponent,
    CellComponent,
    PaginationComponent,
    BadgePillComponent,
    DropdownOverlayComponent,
    GenericAlignAndValignAndBlockComponent,
    TextComponent,
    LayoutComponent,
    PageHeaderComponent,
    PageFooterComponent,
    PageContentComponent,
    ConfirmWindowComponent,
    BrowserInfoComponent,
    EnvComponent,
    SiteSwitchComponent,
];

const uiModules = [
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    ScrollingModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    MatInputModule,
];

@NgModule({
    imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, UiCommonModule, ...uiModules],
    exports: [...components, ...uiModules],
    declarations: [...components],
    providers: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PlatformUiModule {}
