<div
    class="loading-indicator"
    [style.display]="(globalLoadingIndicatorService.numberOfLoadingItems | async) > 0 ? 'block' : 'none'"
>
    <div class="spinner-container">
        <div class="align-self-center">
            <mat-spinner color="primary" class="mx-auto"></mat-spinner>
        </div>
    </div>
</div>
