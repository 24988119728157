import { Pipe, PipeTransform } from '@angular/core';
import { dayInMs } from '../../../../shared/types/constants.types';

@Pipe({
    name: 'toDays',
})
export class ToDaysPipes implements PipeTransform {
    constructor() {}

    /**
     * Convert to human readable date.
     * @param dateStr Date string in ISO format from JSON api, e.g. "2020-10-19T13:28:06.419Z". If number is given, it is expected in seconds.
     * @param justNumeric If numeric output is asked like '0.4d'.
     */
    transform(dateStr: Date | string | number, justNumeric = false, longAgo = false): string {
        const now = new Date();
        const date = new Date(dateStr);

        if (justNumeric) {
            // if number, it is in seconds
            const diff = typeof dateStr === 'number' ? dateStr : now.getTime() - date.getTime();
            return ((diff * 1000) / dayInMs).toFixed(1) + 'd';
        }

        // forward time to the very end of today
        now.setHours(23);
        now.setMinutes(59);
        now.setSeconds(59);
        now.setMilliseconds(999);

        // diff in ms
        const diff = typeof dateStr === 'number' ? dateStr : now.getTime() - date.getTime();

        // today
        // yesterday
        // 2 days ago
        // 3 days ago
        // ...
        // 30 days ago

        if (diff < 0) {
            return 'future';
        }
        if (diff >= 0 && diff < dayInMs) {
            return 'today';
        }
        if (diff <= dayInMs * 2) {
            return 'yesterday';
        }
        for (let i = 3; i <= 31; i++) {
            if (diff <= dayInMs * i) {
                return `${i - 1} days ago`;
            }
        }

        if (!longAgo) {
            // 02. Feb
            const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() + '';
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const mon = months[date.getMonth()];
            // add year only if it is not current
            const yearAppendix = date.getFullYear() != now.getFullYear() ? '. ' + date.getFullYear() : '';

            return day + '. ' + mon + yearAppendix;
        }
        // "long ago" mode
        else {
            // until 6 months ago
            for (let i = 1; i <= 6; i++) {
                if (diff <= dayInMs * 30 * (i + 1)) {
                    return `${i} month${i > 1 ? 's' : ''} ago`;
                }
            }

            return 'long ago';
        }
    }
}
