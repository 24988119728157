import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-ui-box',
    templateUrl: 'box.component.html',
    styleUrls: ['./box.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent implements OnInit {
    @HostBinding('attr.vAlign')
    @Input()
    vAlign: 'start' | 'end' = 'end';

    constructor() {}

    ngOnInit() {}
}
