import { ChangeDetectionStrategy, Component, Renderer2, ViewEncapsulation } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import mixpanel from './common/mixpanel';

@UntilDestroy()
@Component({
    selector: 'scp-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    previousUrl: any;
    subs: Array<Subscription> = [];

    constructor(
        private iconRegistry: MatIconRegistry,
        private sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        this.registerIcon('back', '../assets/icon/arrow-back.svg');
        this.registerIcon('eye', '../assets/icon/eye.svg');
        this.registerIcon('plus', '../assets/icon/plus.svg');
        this.registerIcon('minus', '../assets/icon/minus.svg');
        this.registerIcon('close', '../assets/icon/close.svg');
        this.registerIcon('eye-off', '../assets/icon/eye-off.svg');
        this.registerIcon('logout', '../assets/icon/logout.svg');
        this.registerIcon('more-vertical', '../assets/icon/more-vertical.svg');
        this.registerIcon('drag', '../assets/icon/drag.svg');
        this.registerIcon('error-mark', '../assets/icon/error-mark.svg');

        //add unique bodyClass from data param of each route
        this.router.events
            .pipe(
                untilDestroyed(this),
                filter(event => event instanceof NavigationEnd),
                map(() => this.route.snapshot),
                map(route => {
                    while (route.firstChild) {
                        route = route.firstChild;
                    }
                    return route;
                }),
            )
            .subscribe((route: ActivatedRouteSnapshot) => {
                let currentUrlSlug = route.data.bodyClassName;

                if (this.previousUrl) {
                    this.renderer.removeClass(document.body, this.previousUrl);
                }
                if (currentUrlSlug) {
                    this.renderer.addClass(document.body, currentUrlSlug);
                }
                this.previousUrl = currentUrlSlug;
            });

        this.initTracking();
    }

    registerIcon(name: string, url: string) {
        this.iconRegistry.addSvgIcon(name, this.sanitizer.bypassSecurityTrustResourceUrl(url));
    }

    initTracking() {
        mixpanel.track('App Open');
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.leafRoute()),
                filter((route: ActivatedRoute) => route.outlet === 'primary'),
            )
            .subscribe(route => {
                mixpanel.track('Page View');
            });
    }

    leafRoute() {
        let route = this.route;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route;
    }
}
