import { environment } from './../../environments/environment';
import { Pagination } from './../../../shared/types/app.types';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { pluck, shareReplay, tap } from 'rxjs/operators';
import { Domain, Member, Project, ProjectDetail, ProjectListFilter } from '../../../shared/types/project.types';
import { UserRoles } from '../../../shared/types/user.types';
import { LastModifiedMeta, ListMeta, Response, Status } from '../../../shared/types/http-response.types';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, Subject, forkJoin } from 'rxjs';
import { TaskHtmlDetail, TaskStateHistory } from '../../../shared/types/task.types';
import { isValue } from '../common';
import { TaskFileEntry } from '../monaco-editor/editor.types';
import { TaskResource } from '../resources/tasks.resource';
import { ProjectResource } from '../resources/project.resource';

const CACHE_TIME_MILISECONDS = 10 * 60 * 1000;

@Injectable({
    providedIn: 'root',
})
export class ProjectService implements Resolve<{ detail: ProjectDetail; members: Member[] }> {
    private currentProject: string;

    private projects$: Observable<Project[]>;
    private projectsCache = 0;

    private updatesSubject = new Subject();

    updates = this.updatesSubject.asObservable();

    private refresh = new Subject();

    constructor(private http: HttpClient, private resource: TaskResource, private projectResource: ProjectResource) {}

    fetch(filter?: ProjectListFilter, pagination?: Pagination, mode = UserRoles.styler, extra = 'domains') {
        let params = new HttpParams();

        Object.keys(filter || {}).forEach(key => {
            const value = Array.isArray(filter[key]) ? filter[key].join(',') : isValue(filter[key]) ? filter[key] : '';

            params = params.append(`filter[${key}]`, value);
        });

        if (pagination) {
            params = params.append('page[size]', pagination.size + '');
            params = params.append('page[number]', pagination.number + '');
        }

        return this.http.get<Response<Project[], ListMeta>>(
            `${environment.apiUrl}v1/projects?mode=${mode}&extra=${extra}`,
            {
                params,
            },
        );
    }

    getProjectsCached(filter?: ProjectListFilter, pagination?: Pagination) {
        if (!this.projects$ || this.projectsCache + CACHE_TIME_MILISECONDS < Date.now()) {
            this.projects$ = this.fetch(filter, pagination, UserRoles.superUser, '').pipe(
                pluck('data'),
                shareReplay(1),
            );

            this.projectsCache = Date.now();
        }

        return this.projects$;
    }

    // TODO rework other way - is it applied only in project CRUD screens?
    resolve(route: ActivatedRouteSnapshot) {
        return forkJoin({
            detail: this.getDetail(route.params.projectId),
            members: this.getMembers(route.params.projectId, UserRoles.superUser),
        });
    }

    getDetail(projectId: Project['id']) {
        return this.http
            .get<Response<ProjectDetail, LastModifiedMeta>>(`${environment.apiUrl}v1/projects/${projectId}`)
            .pipe(pluck('data'));
    }

    create(project: Partial<ProjectDetail>, mode = UserRoles.styler) {
        return this.http.post<Response<{project: Project}, LastModifiedMeta>>(`${environment.apiUrl}v1/projects?mode=${mode}`, project).pipe(
            tap(() => {
                this.updatesSubject.next();
            }),
        );
    }

    addDomain(projectId: string, data: Domain, mode = UserRoles.styler) {
        return this.http.post(`${environment.apiUrl}v1/projects/${projectId}/domains?mode=${mode}`, data).pipe(
            tap(() => {
                this.updatesSubject.next();
            }),
        );
    }

    update(projectId: string, project: Partial<ProjectDetail>, mode = UserRoles.styler) {
        return this.http.patch(`${environment.apiUrl}v1/projects/${projectId}?mode=${mode}`, project).pipe(
            tap(() => {
                this.updatesSubject.next();
            }),
        );
    }

    delete(projectId: Project['id'], mode = UserRoles.styler) {
        return this.http.delete(`${environment.apiUrl}v1/projects/${projectId}?mode=${mode}`).pipe(
            tap(() => {
                this.updatesSubject.next();
            }),
        );
    }

    setCurrentProject(project: string) {
        this.currentProject = project;
    }

    getCurrentProject() {
        return this.currentProject;
    }

    getMembers(projectId: Project['id'], mode = UserRoles.styler) {
        return this.http
            .get<Response<Member[], ListMeta>>(`${environment.apiUrl}v1/projects/${projectId}/members?mode=${mode}`)
            .pipe(pluck('data'));
    }

    refreshData() {
        this.refresh.next();
    }

    onRrefreshData(): Observable<unknown> {
        return this.refresh.asObservable();
    }

    getSitemap(projectId: Project['id']) {
        return this.http
            .get<Response<TaskHtmlDetail[], ListMeta>>(`${environment.apiUrl}v1/projects/${projectId}/sitemap`)
            .pipe(pluck('data'));
    }

    patchSitemap(projectId: Project['id'], taskHtml: Partial<TaskHtmlDetail>) {
        return this.http.patch(`${environment.apiUrl}v1/projects/${projectId}/sitemap/${taskHtml.id}`, taskHtml);
    }

    getFiles(
        projectId: Project['id'],
        taskCommitId: TaskStateHistory['taskCommitId'],
    ): Observable<{ files: TaskFileEntry[] }> {
        return this.resource.getProjectFiles(projectId, taskCommitId);
    }

    public addProjectMember(projectId: string, userId: string, relationType: string): Observable<Status> {
        return this.projectResource.addProjectMember(projectId, userId, relationType);
    }
}
