import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'callFunction' })
export class CallFunctionPipe implements PipeTransform {
    transform(func: Function, ...args: any[]): any {
        return func(...args);
    }
}

@Pipe({ name: 'callMethod' })
export class CallMethodPipe implements PipeTransform {
    transform(component: any, func: Function, ...args: any[]): any {
        return func.apply(component, args);
    }
}
