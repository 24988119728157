import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SubRoles, UserDetail } from '../../../shared/types/user.types';
import mixpanel from '../common/mixpanel';
import { RolesService } from './roles.service';
import { UserService } from './user.service';

@Injectable({
    providedIn: 'root',
})
export class CurrentUserService {
    private currentUserSubj = new BehaviorSubject<Partial<UserDetail>>(null);
    public currentUser$: Observable<Partial<UserDetail>>;
    public currentUserDetail$: Observable<UserDetail>;

    constructor(private userService: UserService, private rolesService: RolesService) {
        this.currentUser$ = this.currentUserSubj.asObservable();
        this.currentUserDetail$ = this.currentUserSubj.pipe(
            switchMap(user => {
                if (user?.id) {
                    return this.userService.getDetail(user.id);
                }
                return of(null);
            }),
        );

        this.currentUserDetail$.subscribe(userDetail => {
            if (userDetail) {
                mixpanel.setUserInfo(userDetail);
            }
        });
    }

    public setCurrentUser(user: Partial<UserDetail>): void {
        if (user) {
            const isStyler = this.rolesService.isStyler(user.roles, user.subroles);
            const isOnlyStyler = user.subroles.includes(SubRoles.styler) && user.subroles.length === 1;
            const isTaskManager = this.rolesService.isTaskManager(user.roles, user.subroles);
            const isSuperUserOrAdmin = this.rolesService.isSuperUserOrAdmin(user.roles);
            const isCodeReviewer = this.rolesService.isCodeReviewer(user.subroles);

            mixpanel.identifyUser(user);
            this.currentUserSubj.next({
                ...user,
                isTaskManager,
                isSuperUserOrAdmin,
                isStyler,
                isOnlyStyler,
                isCodeReviewer,
            });
        } else {
            // TODO prerobit na behaviourSubject
            this.currentUserSubj.next(user);
        }
    }

    public getCurrentUser(): Partial<UserDetail> {
        return this.currentUserSubj.getValue();
    }
}
