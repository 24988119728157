import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'boolean',
})
export class BooleanPipe implements PipeTransform {
    transform(value: boolean | null | undefined, positiveText = 'yes', negativeText = 'no'): string {
        if (value === null && value === undefined) {
            return '-';
        }

        return value ? positiveText : negativeText;
    }
}
