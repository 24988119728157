<scp-message-list
    [currentUser]="currentUser"
    [type]="type"
    [messages]="messages$ | async"
    [readStatus]="getReadStatus()"
    (markAsRead)="markMessagesAsRead($event)"
>
</scp-message-list>

<scp-message-form
    #messageForm
    [(message)]="newMessage"
    (send)="sendMessage($event)"
>
</scp-message-form>
