<div
    class="iframe-wrapper"
    [style.width.px]="width"
    [style.height.px]="height"
    [style.transform]="getTransform()"
    [style.margin-left.px]="-0.5 * width * zoom"
    [style.margin-top.px]="-0.5 * height * zoom"
>
    <ng-container #outlet [ngTemplateOutlet]="content"> </ng-container>
</div>

<ng-template #content>
    <iframe #siteReplica [src]="replicaUrl | getSrc"></iframe>
</ng-template>
