import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-page-footer',
    templateUrl: './page-footer.component.html',
    styleUrls: ['./page-footer.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class PageFooterComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
