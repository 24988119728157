import {
    AfterContentInit,
    ChangeDetectionStrategy,
    Component,
    ContentChildren,
    Input,
    OnInit,
    QueryList,
    TemplateRef,
    ViewEncapsulation,
} from '@angular/core';
import { CellComponent } from './cell/cell.component';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'scp-ui-table',
    templateUrl: 'table.component.html',
    styleUrls: ['./table.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnInit, AfterContentInit {
    @Input() columns: TableColumn[];
    @Input() rows: T[];
    @Input() trackBy: (index: number, row: T) => any;
    @Input() extras?: TableExtras<T>;

    @ContentChildren(CellComponent) cells: QueryList<CellComponent>;
    cellTemplates: {
        [property: string]: TemplateRef<any>;
    } = {};

    constructor() {}

    ngOnInit() {}

    ngAfterContentInit() {
        this.cells.map(cell => {
            this.cellTemplates[cell.property] = cell.template;
        });
    }
}

export interface TableExtras<T> {
    getClass?: (row: T) => string;
    getLink?: (row: T) => string;
}

export interface TableColumn {
    title: string;
    property: string;
    size: string;
}
