import { Pipe } from '@angular/core';
import { CurrencyCentsPipe } from './currency-cents.pipe';

@Pipe({
    name: 'wageString',
})
export class WageStringPipe extends CurrencyCentsPipe {
    transform(
        value: number | string,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    transform(
        value: null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): null;
    transform(
        value: number | string | null | undefined,
        currencyCode?: string,
        display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
        digitsInfo?: string,
        locale?: string,
    ): string | null;
    transform(value: number, ...rest: any[]): string {
        const sum = typeof value === 'number' ? value : 0;
        return super.transform(sum, ...rest);
    }
}
