import { ChangeDetectionStrategy, Component, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';

@Component({
    selector: 'scp-ui-button',
    templateUrl: 'button.component.html',
    styleUrls: ['./button.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
    @Input() inProgress = false;
    @Input() type: 'raised' | 'stroked' = 'raised';
    @Input() color: 'primary' | 'accent' | 'basic' | 'warn' = 'basic';
    @Input() href: string;
    @Input() disabled = false;

    @Output() click = new EventEmitter();

    onClick(event) {
        event.stopPropagation();
        if (!this.disabled) {
            this.click.emit(event);
        }
    }
}
