import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { pluck, shareReplay } from 'rxjs/operators';
import { Pagination } from '../../../shared/types/app.types';
import { ListMeta, Response } from '../../../shared/types/http-response.types';
import {
    CreateUserDetail,
    CreateUserStylerAnonymous,
    User,
    UserDetail,
    UserListFilter,
    UserRoles,
} from '../../../shared/types/user.types';
import { UserResource } from '../resources/user.resource';
import { SubRoles } from './../../../shared/types/user.types';

const CACHE_TIME_MILISECONDS = 10 * 60 * 1000;

@Injectable({
    providedIn: 'root',
})
export class UserService implements Resolve<UserDetail> {
    private stylers: Observable<User[]>;
    private taskManagers: Observable<User[]>;

    private cacheStylers = 0;
    private cacheTaskManagers = 0;

    constructor(private userResource: UserResource) {}

    public resolve(route: ActivatedRouteSnapshot): UserDetail | Observable<UserDetail> | Promise<UserDetail> {
        // resolve for Users list
        if (route.params.userId) {
            return this.userResource.getDetail(route.params.userId);
        }
    }

    public getList(filter?: UserListFilter, mode = UserRoles.styler, pagination?: Pagination): Observable<User[]> {
        return this.userResource.getListStream(mode, filter, pagination).pipe(pluck('data'));
    }

    public getListWithMetaData(
        filter?: UserListFilter,
        mode = UserRoles.styler,
        pagination?: Pagination,
    ): Observable<Response<User[], ListMeta>> {
        return this.userResource.getListStream(mode, filter, pagination);
    }

    public getStylersCached(): Observable<User[]> {
        if (!this.stylers || this.cacheStylers + CACHE_TIME_MILISECONDS < Date.now()) {
            this.stylers = this.userResource
                .getList({ subrole: [SubRoles.styler, SubRoles.taskManager] })
                .pipe(shareReplay(1));

            this.cacheStylers = Date.now();
        }

        return this.stylers;
    }

    public getTaskManagersCached(): Observable<User[]> {
        if (!this.taskManagers || this.cacheTaskManagers + CACHE_TIME_MILISECONDS < Date.now()) {
            this.taskManagers = this.userResource.getList({ subrole: [SubRoles.taskManager] }).pipe(shareReplay(1));

            this.cacheTaskManagers = Date.now();
        }

        return this.taskManagers;
    }

    public getDetail(userId: User['id']): Observable<UserDetail> {
        // TODO role logic, specific interface based on role
        return this.userResource.getDetail(userId);
    }

    public create(user: CreateUserDetail, mode = UserRoles.styler): Observable<{ user: { id: string } }> {
        return this.userResource.create(user, mode);
    }

    public createStylerAnonymous(user: CreateUserStylerAnonymous): Observable<{ user: { id: string } }> {
        return this.userResource.createStylerAnonymous(user);
    }

    public update(userId: string, user: Partial<UserDetail>, mode = UserRoles.styler): Observable<Object> {
        return this.userResource.update(userId, user, mode);
    }

    public delete(userId: User['id'], mode = UserRoles.styler) {
        // TODO role logic, specific interface based on role
        return this.userResource.delete(userId, mode);
    }
}
