import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import moment from 'moment';
import * as _ from 'lodash';
import { AbstractControl, AsyncValidatorFn, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { Observable, of, throwError, timer } from 'rxjs';
import { isValue } from '../../utils';
import { GlobalLoadingIndicatorService } from '../global-loading-indicator/global-loading-indicator.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, switchMap } from 'rxjs/operators';
import { Error } from 'shared/types/http-response.types';

export interface ErrorMessage {
    key: string;
    params?: any;
}

@Injectable({
    providedIn: 'root',
})
export class ValidationsService {
    errorMessages = {
        required: { key: 'Povinný údaj' },
        mask: { key: 'validation.error.mask' },
        email: { key: 'Chybný email' },
        duplicateEmail: { key: 'validation.error.duplicateEmail' },
        passwordMismatch: { key: 'validation.error.passwordMismatch' },
        min: e => ({ key: `Najnižšia povolená hodnota je ${e.min}` }), // Hodnota '${e.actual}'
        max: e => ({ key: `Najvyššia povolená hodnota je ${e.max}` }), // Hodnota '${e.actual}',
        maxlength: e => ({ key: `Maximálny povolený počet znakov je '${e.requiredLength}'` }),
        minlength: e => ({ key: `Minimálny počet znakov je '${e.requiredLength}'` }),
        minDate: e => ({
            key: 'validation.error.minDate',
            params: { minDate: e },
        }),
        maxDate: e => ({ key: `Maximálny dátum je ${e}` }),
        pattern: { key: 'Neplatný formát' },
    };

    currencyPipe: CurrencyPipe;

    constructor(
        private snackBar: MatSnackBar,
        @Inject(LOCALE_ID) locale,
        private indicatorService: GlobalLoadingIndicatorService,
    ) {
        this.currencyPipe = new CurrencyPipe(locale);
    }

    getDateFromRC(rc: string): string {
        const length = rc.length;
        // do roku 1954 = 6 + 3 cisla po roku 1954 6 + 4 cisla
        if (length < 9 || !+rc) {
            return null;
        }
        // Number constructor sa nepouziva radsej to pretypovat cez +
        const y = rc.substring(0, 2);
        const year = +(length === 9 ? 19 + y : +y > 53 ? 19 + y : 20 + y);
        const m = +rc.substring(2, 4);
        const month = +(m > 50 ? m - 51 : m - 1);
        const day = +rc.substring(4, 6);
        // preco to nieje key-value? lebo v TS nemusi byt
        const date = moment({
            year,
            month,
            day,
        });
        // console.log(`${day}/${month}/${year}`);
        return date.isValid() ? date.toJSON() : null;
    }

    min(min: number, currency = true, currencyCode = 'EUR', display = 'code', digitsInfo = '1.2-2') {
        const validatorFn = Validators.min(min);
        return (control: AbstractControl) => {
            const validationError = validatorFn(control);
            if (currency && validationError && validationError.min && isValue(validationError.min.min)) {
                validationError.min.min = this.currencyPipe.transform(
                    validationError.min.min,
                    currencyCode,
                    display,
                    digitsInfo,
                    'sk',
                );
            }

            return validationError;
        };
    }

    max(max: number, currency = true, currencyCode = 'EUR', display = 'code', digitsInfo = '1.2-2') {
        const validatorFn = Validators.max(max);
        return (control: AbstractControl) => {
            const validationError = validatorFn(control);
            if (currency && validationError && validationError.max && isValue(validationError.max.max)) {
                validationError.max.max = this.currencyPipe.transform(
                    validationError.max.max,
                    currencyCode,
                    display,
                    digitsInfo,
                    'sk',
                );
            }

            return validationError;
        };
    }

    minDate(minDate: string | moment.Moment): ValidatorFn {
        return (control: AbstractControl) => {
            const errors: any = {};
            if (control && isValue(control.value)) {
                if (moment(control.value).diff(minDate, 'days', true) < 0) {
                    errors.minDate = moment(minDate).format('DD.MM.YYYY');
                }
            }
            return errors.minDate ? errors : null;
        };
    }

    maxDate(maxDate: string | moment.Moment): ValidatorFn {
        return (control: AbstractControl) => {
            const errors: any = {};
            if (control && isValue(control.value)) {
                if (moment(control.value).diff(maxDate, 'days', true) > 0) {
                    errors.maxDate = moment(maxDate).format('DD.MM.YYYY');
                }
            }
            return errors.maxDate ? errors : null;
        };
    }

    minMaxDate(minDate: string | moment.Moment, maxDate: string | moment.Moment): ValidatorFn {
        return (control: AbstractControl) => {
            const minDateValidation = this.minDate(minDate)(control);
            const maxDateValidation = this.maxDate(maxDate)(control);

            return minDateValidation || maxDateValidation ? _.assign({}, minDateValidation, maxDateValidation) : null;
        };
    }

    uniqueEmail(validatorService: any, starsId: string): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors> => {
            if (control && isValue(control.value)) {
                return timer(1000).pipe(
                    switchMap(() => {
                        return validatorService
                            .validateEmail(control.value, starsId)
                            .pipe(
                                map((result: { isDuplicate: boolean }) =>
                                    result.isDuplicate ? { duplicateEmail: true } : null,
                                ),
                            );
                    }),
                );
            } else {
                return of(null);
            }
        };
    }

    passwordMatch(otherPassword: string): ValidatorFn {
        return (control: AbstractControl) => {
            const errors: any = {};
            if (control && isValue(control.value) && otherPassword && isValue(otherPassword)) {
                errors.passwordMismatch = control.value !== otherPassword;
            }
            return errors.passwordMismatch ? errors : null;
        };
    }

    showError(message: string, action?: string, time?: number) {
        this.snackBar.open(message, action || 'x', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: time || 15000,
            panelClass: ['mat-snack-bar', 'error'],
        });
    }

    showSuccess(message: string, action?: string, time?: number) {
        this.snackBar.open(message, action || 'x', {
            verticalPosition: 'top',
            horizontalPosition: 'right',
            duration: time || 10000,
            panelClass: ['mat-snack-bar', 'success'],
        });
    }

    getErrorHandler() {
        return (error: any) => {
            console.log(error);
            this.indicatorService.stopAll(); // ked tu bolo len endLoad(), stale sa tam jeden loading tocil
            this.showError(
                error.error?.error?.message ||
                    error.message ||
                    compileErrorMessage(error) ||
                    error.error?.message ||
                    'Unknown error',
            );
            return throwError({ error });
        };
    }

    getErrorHandlerDontRethrow<T>() {
        return ({ error }) => {
            console.log(error);
            this.indicatorService.stopAll(); // ked tu bolo len endLoad(), stale sa tam jeden loading tocil
            this.showError(error.message || compileErrorMessage(error) || error.error?.message || 'Unknown error');
            return of<T>(null);
        };
    }
}

export function throwCommonError(errorText) {
    return throwError(new CommonError(errorText));
}

export class CommonError {
    error: any = {};
    constructor(errorText) {
        if (errorText) {
            this.error = { errorText };
        }
    }
}

function compileErrorMessage(error) {
    if (error.error?.errors?.length > 0) {
        const resulMessage = (error.error as Error).errors.reduce((sum, nextError) => {
            return sum + `\n ${nextError.message.replace('Property', nextError.property)}`;
        }, '');

        return resulMessage;
    }

    return null;
}
