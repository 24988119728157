import { PageContext } from './../../../../shared/types/task.types';
import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewEncapsulation,
} from '@angular/core';

const PLATFORMS = {
    windows: ['Win32', 'Windows', 'OS/2', 'Pocket PC', 'Win16', 'WinCE'],
    apple: ['iPhone', 'iPod', 'iPad', 'Mac'],
    linux: ['linux'],
};

@Component({
    selector: 'scp-browser-info',
    templateUrl: './browser-info.component.html',
    styleUrls: ['./browser-info.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowserInfoComponent implements OnChanges {
    @Input() browser: PageContext['browser'];

    platform = '';

    browserName = 'chrome';

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.browser && changes.browser.currentValue !== changes.browser.previousValue) {
            this.detectBrowser();
        }
    }
    detectBrowser() {
        if (this.browser && this.browser.platform) {
            const platformLowerCase = this.browser.platform.toLowerCase();
            this.platform = Object.keys(PLATFORMS).find(key =>
                PLATFORMS[key].some(platformString => platformLowerCase.indexOf(platformString.toLowerCase()) >= 0),
            );

            this.browserName = this.browser.userAgent.indexOf('Chrome/') >= 0 ? 'chrome' : 'safari';
        } else {
            this.platform = '';
        }
    }
}
