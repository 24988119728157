import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AlertsService {
    private alertsSubject = new Subject<Alert[]>();
    private alerts: Alert[] = [];

    constructor() {}

    addAlert(message: string, type: AlertType) {
        const newAlert: Alert = { type, message };

        this.alerts.push(newAlert);
        this.alerts = [...this.alerts];
        this.alertsSubject.next(this.alerts);

        setTimeout(() => {
            const index = this.alerts.findIndex(alert => alert === newAlert);
            this.removeAlert(index);
        }, 10000);
    }

    removeAlert(index: number) {
        this.alerts.splice(index, 1);
        this.alerts = [...this.alerts];
        this.alertsSubject.next(this.alerts);
    }

    clearAlerts() {
        this.alerts = [];
        this.alertsSubject.next(this.alerts);
    }

    getAlerts() {
        return this.alertsSubject.asObservable();
    }
}

export interface Alert {
    type: AlertType;
    message: string;
}

export enum AlertType {
    success = 'success',
    warning = 'warning',
    error = 'error',
    info = 'info',
}
