import {
    Directive,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    TemplateRef,
    ViewContainerRef,
} from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Directive({ selector: '[overlay]' })
export class OverlayDirective implements OnInit, OnDestroy {
    @Input('overlay') content: OverlayableElement;
    @Input() config: OverlayConfig;

    private overlayRef: OverlayRef;
    private overlayClosed = new Subject();

    @HostListener('click')
    open() {
        this.content.close
            .pipe(untilDestroyed(this), takeUntil(this.overlayClosed))
            .subscribe(() => this.detachOverlay());
        this.attachOverlay();
    }

    constructor(private elementRef: ElementRef, private vcr: ViewContainerRef, private overlay: Overlay) {}

    ngOnInit() {
        const overlayConfig = this.getOverlayConfig();
        this.overlayRef = this.overlay.create(overlayConfig);

        this.overlayRef
            .backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => this.detachOverlay());
    }

    ngOnDestroy() {
        this.overlayRef.dispose();
        this.overlayClosed.unsubscribe();
    }

    private attachOverlay(): void {
        if (!this.overlayRef.hasAttached()) {
            const templatePortal = new TemplatePortal(this.content.templateRef, this.vcr);

            this.overlayRef.attach(templatePortal);
        }
    }

    private detachOverlay(): void {
        if (this.overlayRef.hasAttached()) {
            this.overlayRef.detach();
        }
        this.overlayClosed.next(true);
    }

    private getOverlayConfig() {
        const positionStrategy = this.overlay
            .position()
            .flexibleConnectedTo(this.elementRef)
            .withPositions([
                new ConnectionPositionPair(
                    { originX: 'start', originY: 'bottom' },
                    { overlayX: 'start', overlayY: 'top' },
                ),
                new ConnectionPositionPair(
                    { originX: 'start', originY: 'top' },
                    { overlayX: 'start', overlayY: 'bottom' },
                ),
            ]);

        return new OverlayConfig({
            ...this.config,
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy,
        });
    }
}

export interface OverlayableElement {
    close: EventEmitter<void>;
    templateRef: TemplateRef<any>;
}
