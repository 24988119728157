import { Component, Inject } from '@angular/core';
import { GlobalLoadingIndicatorService } from './global-loading-indicator.service';

@Component({
    selector: 'scp-global-loading-indicator',
    templateUrl: './global-loading-indicator.component.html',
    styleUrls: ['./global-loading-indicator.component.scss'],
})
export class GlobalLoadingIndicatorComponent {
    constructor(public globalLoadingIndicatorService: GlobalLoadingIndicatorService) {}
}
