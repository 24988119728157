import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-ui-content',
    templateUrl: 'content.component.html',
    styleUrls: ['./content.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
