import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { pluck, tap } from 'rxjs/operators';
import { Pagination } from '../../../shared/types/app.types';
import { LastModifiedMeta, ListMeta, Response } from '../../../shared/types/http-response.types';
import {
    CreateUserStylerAnonymous,
    User,
    UserDetail,
    UserListFilter,
    UserRoles,
} from '../../../shared/types/user.types';
import { RolesService } from '../services/roles.service';
import { CreateUserDetail } from './../../../shared/types/user.types';
import { environment } from './../../environments/environment';

const CACHE_TIME_MILISECONDS = 10 * 60 * 1000;

@Injectable({
    providedIn: 'root',
})
export class UserResource {
    private updatesSubject = new Subject();

    public updates = this.updatesSubject.asObservable();

    constructor(private http: HttpClient, private rolesService: RolesService) {}
    public getList(filter?: UserListFilter, mode = UserRoles.styler, pagination?: Pagination): Observable<User[]> {
        return this.getListStream(mode, filter, pagination).pipe(pluck('data'));
    }

    public getListWithMetaData(
        filter?: UserListFilter,
        mode = UserRoles.styler,
        pagination?: Pagination,
    ): Observable<Response<User[], ListMeta>> {
        return this.getListStream(mode, filter, pagination);
    }

    public getDetail(userId: User['id']): Observable<UserDetail> {
        // TODO role logic, specific interface based on role
        return this.http
            .get<Response<UserDetail, LastModifiedMeta>>(`${environment.apiUrl}v1/users/${userId}`)
            .pipe(pluck('data'));
    }

    public create(
        user: CreateUserDetail | CreateUserStylerAnonymous,
        mode = UserRoles.styler,
    ): Observable<{ user: { id: string } }> {
        return this.http
            .post<Response<{ user: { id: string } }>>(`${environment.apiUrl}v1/users?mode=${mode}`, user)
            .pipe(
                pluck('data'),
                tap(() => {
                    this.updatesSubject.next();
                }),
            );
    }

    public createStylerAnonymous(user: CreateUserStylerAnonymous): Observable<{ user: { id: string } }> {
        return this.http
            .post<Response<{ user: { id: string } }>>(`${environment.apiUrl}v1/users/anonymous-styler`, user, {
                headers: { skipInterceptors: 'true' },
            })
            .pipe(
                pluck('data'),
                tap(() => {
                    this.updatesSubject.next();
                }),
            );
    }

    public update(userId: string, user: Partial<UserDetail>, mode = UserRoles.styler): Observable<Object> {
        return this.http
            .patch(`${environment.apiUrl}v1/users/${userId}?mode=${mode}`, user)
            .pipe(tap(() => this.updatesSubject.next()));
    }

    public delete(userId: User['id'], mode = UserRoles.styler) {
        // TODO role logic, specific interface based on role
        return this.http
            .delete(`${environment.apiUrl}v1/users/${userId}?mode=${mode}`)
            .pipe(tap(() => this.updatesSubject.next()));
    }

    public getListStream(
        mode: UserRoles,
        filter?: UserListFilter,
        pagination?: Pagination,
    ): Observable<Response<User[], ListMeta>> {
        let params = new HttpParams();

        if (filter) {
            if (filter.subrole) {
                params = params.append('filter[subrole]', filter.subrole.join(','));
            }
        }

        if (pagination) {
            params = params.append('page[size]', pagination.size + '');
            params = params.append('page[number]', pagination.number + '');
        }

        return this.http.get<Response<User[], ListMeta>>(`${environment.apiUrl}v1/users?mode=${mode}&page[size]=500`, {
            params,
        });
    }
}
