import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class PageHeaderComponent implements OnInit {
    @Input() backButton = false;

    constructor() {}

    ngOnInit() {}
}
