import { UserRoles, UserDetail } from './../../../../shared/types/user.types';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { OverlayableElement } from '../../common/directives/overlay.directive';
import { MenuItem } from 'shared/types/app.types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CurrentUserService } from '../../services/current-user.service';

@UntilDestroy()
@Component({
    selector: 'scp-ui-header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
    @Input() logoutButton = true;
    @Input() optionsDropdown?: OverlayableElement;

    private menuItems: MenuItem[] = [
        {
            url: '/tasks',
            title: 'Tasks',
            enabled: true,
            roles: [],
        },
        {
            url: '/users',
            title: 'Users',
            enabled: true,
            roles: [UserRoles.admin, UserRoles.superUser],
        },
        {
            url: '/projects',
            title: 'Projects',
            enabled: true,
            roles: [UserRoles.admin, UserRoles.superUser],
        },
        {
            url: '/turns',
            title: 'Turns',
            enabled: true,
            roles: [UserRoles.admin, UserRoles.superUser],
        },
    ];

    visibleMenuItems: MenuItem[] = [];

    constructor(
        private authService: AuthService,
        public userService: CurrentUserService,
        private router: Router,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        this.userService.currentUser$.pipe(untilDestroyed(this)).subscribe(user => {
            this.updateMenu(user);
        });
    }

    get currentUser$() {
        return this.userService.currentUserDetail$;
    }

    updateMenu(currentUser: Partial<UserDetail>) {
        this.menuItems.forEach(item => {
            if (item.roles && item.roles.length > 0) {
                item.enabled = item.roles.some(role => currentUser?.roles.indexOf(role as UserRoles) >= 0);
            }
        });

        this.visibleMenuItems = this.menuItems.filter(item => item.enabled);
    }

    logout() {
        this.authService.logOut().subscribe(() => {
            this.router.navigate(['./new/login']);
        });
    }
}
