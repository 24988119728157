import { ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Alert, AlertsService } from './alerts.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'ui-alerts',
    templateUrl: 'alerts.component.html',
    styleUrls: ['./alerts.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertsComponent implements OnInit {
    alerts$: Observable<Alert[]>;

    constructor(private alertsService: AlertsService) {}

    ngOnInit() {
        this.alerts$ = this.alertsService.getAlerts();
    }

    removeAlert(index: number) {
        this.alertsService.removeAlert(index);
    }
}
