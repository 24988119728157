import { Injectable } from '@angular/core';
import { SubRoles, UserRoles } from '../../../shared/types/user.types';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    public isTaskManager(roles: UserRoles[], subRoles: SubRoles[]): boolean {
        return (
            this.isSuperUserOrAdmin(roles) ||
            (roles.includes(UserRoles.styler) && subRoles.includes(SubRoles.taskManager))
        );
    }

    public isStyler(roles: UserRoles[], subRoles: SubRoles[]): boolean {
        return (
            this.isSuperUserOrAdmin(roles) || (roles.includes(UserRoles.styler) && subRoles.includes(SubRoles.styler))
        );
    }

    public isAdmin(roles: UserRoles[]): boolean {
        return roles.includes(UserRoles.admin);
    }

    public isSuperUser(roles: UserRoles[]): boolean {
        return roles.includes(UserRoles.superUser);
    }

    public isSuperUserOrAdmin(roles: UserRoles[]): boolean {
        return this.isSuperUser(roles) || this.isAdmin(roles);
    }

    public isCodeReviewer(subRoles: SubRoles[]): boolean {
        return subRoles.includes(SubRoles.codeReviewer);
    }
}
