import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-ui-flex-container',
    templateUrl: 'flex-container.component.html',
    styleUrls: ['./flex-container.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexContainerComponent implements OnInit {
    @HostBinding('attr.direction')
    @Input()
    direction: 'row' | 'column' = 'row';

    @HostBinding('attr.justify')
    @Input()
    justify: 'start' | 'end' | 'center' = 'start';

    constructor() {}

    ngOnInit() {}
}
