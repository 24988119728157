<div class="w-100 d-flex flex-row">
    <div class="d flex flex-column">
        <scp-ui-button
            [color]="'basic'"
            [disabled]="isEditing || disabled || htmlSources?.length < 2"
            [overlay]="htmlDropdown"
            [config]="{ panelClass: 'scp-root' }"
        >
            {{ selectedHtmlSource?.htmlLabel }}
            <mat-icon>arrow_drop_down</mat-icon>
        </scp-ui-button>
    </div>
    <div class="d flex flex-column" *ngIf="!readonly">
        <div class="p-1">
            <mat-checkbox
                [checked]="sitemap"
                (change)="changeSitemap($event.checked)"
                class="mb-2"
                [disabled]="isEditing || !editable"
            >
                Show this HTML in sitemap
            </mat-checkbox>
            <div *ngIf="selectedHtmlSource?.html.sitemap && !isEditing">
                <span>
                    {{ selectedHtmlSource?.html.sitemapName }}
                    <span
                        class="date-time"
                        [attr.title]="selectedHtmlSource?.html.createdDatetime | date: 'dd. MMM y, HH:mm:ss'"
                    >
                        (<span class="date">{{ selectedHtmlSource?.html.createdDatetime | toDays }}</span
                        >&nbsp; <span class="time">{{ selectedHtmlSource?.html.createdDatetime | toTime }}</span
                        >)
                    </span>
                </span>
                <mat-icon [hidden]="!editable" class="action-icon" (click)="edit()">edit</mat-icon>
            </div>
            <div class="w-100" *ngIf="isEditing">
                <div class="w-100">
                    <mat-form-field>
                        <mat-label>Site name</mat-label>
                        <input matInput [(ngModel)]="sitemapName" type="text" [disabled]="!editable" required />
                    </mat-form-field>
                </div>
                <div class="w-100 d-flex">
                    <scp-ui-button color="primary" (click)="save()"> Save </scp-ui-button>
                    <scp-ui-button (click)="cancel()"> Cancel </scp-ui-button>
                </div>
            </div>
        </div>
    </div>
</div>

<ui-dropdown-overlay #htmlDropdown>
    <ui-dropdown-item
        *ngFor="let source of htmlSources"
        (click)="changeSelectedSource(source)"
        [class.d-none]="!source.html"
    >
        <mat-icon [class.d-none]="!source.sitemap" title="HTML from Sitemap">language</mat-icon>
        &nbsp;{{ source.htmlLabel }}&nbsp;
        <span class="date-time" [attr.title]="source.html?.createdDatetime | date: 'dd. MMM y, HH:mm:ss'">
            (<span class="date">{{ source.html?.createdDatetime | toDays }}</span>
            <span class="time">&nbsp;{{ source.html?.createdDatetime | toTime }}</span
            >)
        </span>
         <small>{{ source?.html?.tokenizedHash | slice:0:4 }}</small>
    </ui-dropdown-item>
</ui-dropdown-overlay>
