import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivateChild, CanActivate {
    constructor(public auth: AuthService, public router: Router) {}

    canActivate(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            tap(isLoggedIn => {
                if (isLoggedIn) {
                    this.router.navigate(['tasks']);
                }
            }),
            map(isLoggedIn => !isLoggedIn),
        );
    }

    canActivateChild(): Observable<boolean> {
        return this.auth.isLoggedIn().pipe(
            tap(
                isLoggedIn => {
                    if (!isLoggedIn) {
                        this.router.navigate(['new/login']);
                    }
                },
                err => {
                    this.router.navigate(['new/login']);
                    return throwError(err);
                },
            ),
        );
    }
}
