import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { Meta, Response, Status } from '../../../shared/types/http-response.types';
import { Project } from '../../../shared/types/project.types';
import { TaskDetail, TaskStateHistory } from '../../../shared/types/task.types';
import { environment } from '../../environments/environment';
import { TaskFileEntry } from '../monaco-editor/editor.types';

@Injectable({ providedIn: 'root' })
export class TaskResource {
    constructor(private http: HttpClient) {}

    public getProjectFiles(
        projectId: Project['id'],
        taskCommitId: TaskStateHistory['taskCommitId'],
    ): Observable<{ files: TaskFileEntry[] }> {
        return this.http
            .get<Response<{ files: TaskFileEntry[] }, Meta>>(
                `${environment.apiUrl}v1/projects/${projectId}/files/${taskCommitId}`,
            )
            .pipe(pluck('data'));
    }

    public logTaskActivityDuration(
        projectId: Project['id'],
        taskId: TaskDetail['id'],
        stateId: string,
        platformActivityDuration: number,
    ): Observable<Status> {
        return this.http.patch<Status>(
            `${environment.apiUrl}v1/projects/${projectId}/tasks/${taskId}/states/${stateId}`,
            {
                platformActivityDuration,
            },
        );
    }

    updateFiles(projectId: string, taskId: string, files: TaskFileEntry[]) {
        return this.http.post(`${environment.apiUrl}v1/projects/${projectId}/tasks/${taskId}/files`, { files });
    }
}
