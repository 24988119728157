import { TaskState } from './../../../../shared/types/task.types';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-state-icon',
    templateUrl: 'state-icon.component.html',
    styleUrls: ['./state-icon.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateIconComponent {
    @Input()
    @HostBinding('attr.type')
    type: TaskState;

    constructor() {}

    getIconName() {
        switch (this.type) {
            case TaskState.new:
                return 'hourglass_empty'; // 'add_circle';

            case TaskState.amended:
                return 'hourglass_empty'; // 'replay_circle_filled';

            case TaskState.analyzing:
                return 'account_circle';

            case TaskState.unclear:
                return 'help';

            case TaskState.queued:
                return 'hourglass_empty'; // 'playlist_add_circle';

            case TaskState.revalidate:
                return 'help';

            case TaskState.checking:
                return 'account_circle';

            case TaskState.inProgress:
                return 'account_circle';

            case TaskState.forReview:
                return 'hourglass_empty'; // 'supervised_user_circle';

            case TaskState.reviewing:
                return 'account_circle';

            case TaskState.disapproved:
                return 'hourglass_empty'; // 'gpp_bad';

            case TaskState.reviewed:
                return 'hourglass_empty'; // 'gpp_good';

            case TaskState.completing:
                return 'account_circle';

            case TaskState.refused:
                return 'hourglass_empty'; // 'replay_circle_filled';

            case TaskState.done:
                return 'playlist_add_check_circle';

            case TaskState.accepted:
                return 'check_circle';

            case TaskState.rejected:
                return 'cancel';

            case TaskState.canceled:
                return 'highlight_off';

            case TaskState.blocked:
                return 'pause_circle';

            default:
                return 'lens';
        }
    }

    @HostBinding('attr.role')
    get roleType() {
        switch (this.type) {
            case TaskState.new:
            case TaskState.amended:
            case TaskState.analyzing:
            case TaskState.revalidate:
            case TaskState.reviewed:
            case TaskState.completing:
            case TaskState.rejected:
                return 'manager';

            case TaskState.queued:
            case TaskState.checking:
            case TaskState.inProgress:
            case TaskState.disapproved:
            case TaskState.blocked:
                return 'styler';

            case TaskState.reviewing:
            case TaskState.forReview:
            case TaskState.refused:
                return 'reviewer';

            case TaskState.unclear:
            case TaskState.done:
            case TaskState.accepted:
            case TaskState.canceled:
                return 'customer';
        }
    }
}
