import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'ui-dropdown-overlay',
    templateUrl: 'dropdown-overlay.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownOverlayComponent implements OnInit {
    @Output() close = new EventEmitter<void>();
    @ViewChild(TemplateRef) templateRef: TemplateRef<void>;

    @Input() doNotCloseOnClickInside = false;

    @Input()
    set forceClose(bool: boolean) {
        if (bool) this.close.emit();
    }

    constructor() {}

    ngOnInit() {}
}
