import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { Alert } from '../alerts.service';

@Component({
    selector: 'ui-alert',
    templateUrl: 'alert.component.html',
    styleUrls: ['./alert.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent implements OnInit {
    @Input() alert: Alert;

    @Output() close = new EventEmitter<void>();

    @HostBinding('attr.type')
    get type() {
        return this.alert.type;
    }

    constructor() {}

    ngOnInit() {}

    onCloseBtnClick() {
        this.close.emit();
    }
}
