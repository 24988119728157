import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';

import { MONACO_EDITOR_CONFIG } from './editor.utils';
import { DiffEditorComponent } from './diff-editor.component';
import { EditorComponent } from './editor.component';
import { ScpMonacoEditorConfig } from './editor.types';
import { MultifileEditorComponent } from './multifile/multifile-editor.component';
import { MultifileEditorSearchResultsComponent } from './multifile/multifile-editor-search-results-component';
import { MultifileEditorTabsComponent } from './multifile/multifile-editor-tabs.component';

/**
 * The Monaco editor is not supported in mobile browsers or mobile web frameworks.
 */
@NgModule({
    imports: [CommonModule],
    declarations: [
        EditorComponent,
        DiffEditorComponent,
        MultifileEditorComponent,
        MultifileEditorSearchResultsComponent,
        MultifileEditorTabsComponent,
    ],
    exports: [EditorComponent, DiffEditorComponent, MultifileEditorComponent],
})
export class MonacoEditorModule {
    public static forRoot(config: ScpMonacoEditorConfig = {}): ModuleWithProviders<MonacoEditorModule> {
        return {
            ngModule: MonacoEditorModule,
            providers: [{ provide: MONACO_EDITOR_CONFIG, useValue: config }],
        };
    }
}
