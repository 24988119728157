<ui-page-header>
    <scp-ui-icon [name]="'back'" back-button (click)="close()"></scp-ui-icon>
    <h1>Confirm</h1>
</ui-page-header>
<ui-page-content>
    <ui-text class="extra-padding pre-wrap" align="center">{{ question }}</ui-text>
    <ui-text *ngIf="options.description" class="extra-padding-after secondary small pre-wrap" align="center">{{
        options.description
    }}</ui-text>
</ui-page-content>
<ui-page-footer class="narrow">
    <ui-layout>
        <scp-ui-button (click)="no()">No</scp-ui-button>
        <scp-ui-button [color]="options.negativeAction ? 'accent' : 'primary'" (click)="yes()"> Yes</scp-ui-button>
    </ui-layout>
</ui-page-footer>
