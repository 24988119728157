import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeExtraEn from '@angular/common/locales/extra/en';
import { DEFAULT_CURRENCY_CODE, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { API_BASE_URL } from '../../messaging';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiCommonModule } from './common/ui-common.module';
import { LayoutComponent } from './layout/layout.component';
import { MonacoEditorModule } from './monaco-editor/monaco-editor.module';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { PlatformUiModule } from './ui/platform-ui.module';

localeEn[11] = ['HH:mm', 'HH:mm:ss', 'HH:mm:ss z', 'HH:mm:ss zzzz'];

registerLocaleData(localeEn, 'en-US', localeExtraEn);

@NgModule({
    declarations: [AppComponent, LayoutComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        CommonModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        OverlayModule,
        UiCommonModule,
        PlatformUiModule,
        MonacoEditorModule.forRoot(), // use forRoot() in main app module only.
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: API_BASE_URL, useValue: `${environment.apiUrl}` },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    ],
    bootstrap: [AppComponent],
    exports: [],
})
export class AppModule {}
