import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-text',
    templateUrl: './text.component.html',
    styleUrls: ['./text.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class TextComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
