import { TaskPin } from './../../../shared/types/task.types';
import { environment } from './../../environments/environment';
import { FormGroup } from '@angular/forms';
import animateScrollTo from 'animated-scroll-to';
import { ElementRef } from '@angular/core';
import * as moment from 'moment';
import { ReplicaProps, ReplicaURLProps } from '../../../shared/types/interfaces/replica.types';
import { Base64 } from 'js-base64';

/**
 * pocet fixnych pixelov od vrchu stranky, ktore ...
 */
export const GLOBAL_OFFSET = 150;

export const LOREM_IPSUM =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. In fringilla velit sem. Aenean orci mi, mollis in condimentum nec, dictum eget libero. Pellentesque quis rutrum libero. Vivamus a ornare neque. Vivamus varius rutrum interdum. Sed vitae tempor justo, dapibus maximus justo. Nunc ut placerat odio. Phasellus dignissim justo et enim interdum, eu fermentum purus laoreet. Duis tincidunt pulvinar felis, in euismod magna imperdiet et. Cras molestie cursus neque, aliquam ullamcorper dui sagittis in. Suspendisse potenti. Donec consequat lorem non accumsan ultrices. Mauris velit nisl, aliquam vel viverra ac, vulputate ac enim.';

export function formGroupConfigToValues(config) {
    return Object.keys(config)
        .map(key => {
            return {
                [key]: Array.isArray(config[key]) ? config[key][0] : config[key],
            };
        })
        .reduce((acc, current) => ({ ...acc, ...current }), {});
}

export function plural(num: number): boolean {
    // tslint:disable-next-line: no-bitwise
    return num > 1 && num < 5 && ~~(num / 10) !== 1;
}

export function mergeRouteSnapshotData(route) {
    return route ? { ...mergeRouteSnapshotData(route.parent), ...route.data } : {};
}

export function removeDiacritics(str): string {
    if (!str) {
        return str;
    }

    const diacriticsMap = {
        // tslint:disable-next-line:max-line-length
        A: /[\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F]/g,
        AA: /[\uA732]/g,
        AE: /[\u00C6\u01FC\u01E2]/g,
        AO: /[\uA734]/g,
        AU: /[\uA736]/g,
        AV: /[\uA738\uA73A]/g,
        AY: /[\uA73C]/g,
        B: /[\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181]/g,
        C: /[\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E]/g,
        D: /[\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779]/g,
        DZ: /[\u01F1\u01C4]/g,
        Dz: /[\u01F2\u01C5]/g,
        // tslint:disable-next-line:max-line-length
        E: /[\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E]/g,
        F: /[\u0046\u24BB\uFF26\u1E1E\u0191\uA77B]/g,
        G: /[\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E]/g,
        H: /[\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D]/g,
        I: /[\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197]/g,
        J: /[\u004A\u24BF\uFF2A\u0134\u0248]/g,
        K: /[\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2]/g,
        L: /[\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780]/g,
        LJ: /[\u01C7]/g,
        Lj: /[\u01C8]/g,
        M: /[\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C]/g,
        N: /[\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4]/g,
        NJ: /[\u01CA]/g,
        Nj: /[\u01CB]/g,
        // tslint:disable-next-line:max-line-length
        O: /[\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C]/g,
        OI: /[\u01A2]/g,
        OO: /[\uA74E]/g,
        OU: /[\u0222]/g,
        P: /[\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754]/g,
        Q: /[\u0051\u24C6\uFF31\uA756\uA758\u024A]/g,
        R: /[\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782]/g,
        S: /[\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784]/g,
        T: /[\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786]/g,
        TZ: /[\uA728]/g,
        // tslint:disable-next-line:max-line-length
        U: /[\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244]/g,
        V: /[\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245]/g,
        VY: /[\uA760]/g,
        W: /[\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72]/g,
        X: /[\u0058\u24CD\uFF38\u1E8A\u1E8C]/g,
        Y: /[\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE]/g,
        Z: /[\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762]/g,
        // tslint:disable-next-line:max-line-length
        a: /[\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250]/g,
        aa: /[\uA733]/g,
        ae: /[\u00E6\u01FD\u01E3]/g,
        ao: /[\uA735]/g,
        au: /[\uA737]/g,
        av: /[\uA739\uA73B]/g,
        ay: /[\uA73D]/g,
        b: /[\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253]/g,
        c: /[\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184]/g,
        d: /[\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A]/g,
        dz: /[\u01F3\u01C6]/g,
        // tslint:disable-next-line:max-line-length
        e: /[\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD]/g,
        f: /[\u0066\u24D5\uFF46\u1E1F\u0192\uA77C]/g,
        g: /[\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F]/g,
        h: /[\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265]/g,
        hv: /[\u0195]/g,
        i: /[\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131]/g,
        j: /[\u006A\u24D9\uFF4A\u0135\u01F0\u0249]/g,
        k: /[\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3]/g,
        l: /[\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747]/g,
        lj: /[\u01C9]/g,
        m: /[\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F]/g,
        n: /[\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5]/g,
        nj: /[\u01CC]/g,
        // tslint:disable-next-line:max-line-length
        o: /[\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275]/g,
        oi: /[\u01A3]/g,
        ou: /[\u0223]/g,
        oo: /[\uA74F]/g,
        p: /[\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755]/g,
        q: /[\u0071\u24E0\uFF51\u024B\uA757\uA759]/g,
        r: /[\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783]/g,
        s: /[\u0073\u24E2\uFF53\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B]/g,
        ss: /[\u00DF]/g,
        t: /[\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787]/g,
        tz: /[\uA729]/g,
        // tslint:disable-next-line:max-line-length
        u: /[\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289]/g,
        v: /[\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C]/g,
        vy: /[\uA761]/g,
        w: /[\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73]/g,
        x: /[\u0078\u24E7\uFF58\u1E8B\u1E8D]/g,
        y: /[\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF]/g,
        z: /[\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763]/g,
    };

    for (const x in diacriticsMap) {
        if (diacriticsMap.hasOwnProperty(x)) {
            // Iterate through each keys in the above object and perform a replace
            str = str.replace(diacriticsMap[x], x);
        }
    }

    return str;
}

/**
 * Check if is value defined
 * @param val any
 * @returns true if (val !== '' && val !== null && val !== undefined)
 */
export function isValue(val: any) {
    return val !== '' && val !== null && val !== undefined;
}

/**
 * return true if value is ''/null/undefined
 * @param val any
 * @returns false if (val !== '' && val !== null && val !== undefined)
 */
export function hasNotValue(val: any) {
    return !isValue(val);
}

/**
 * Compare function for Array.sort(), compares position in DOM
 * @param a First element to compare
 * @param b Second element to compare
 */
export function compareDOMPositionFunction(a: Element, b: Element) {
    if (a === b) {
        return 0;
    }
    // tslint:disable-next-line:no-bitwise no-magic-numbers
    if (a.compareDocumentPosition(b) & 2) {
        return 1;
    }
    return -1;
}

/**
 * Takes array of elements and focuses first focusable and visible element anywhere in contents of them.
 * @param parents Array of HTML elements, or one HTML element.
 */
export function focusFirstFocusableElement(parents: Element | Element[], classSelector = '') {
    const focusableElementsQuerySelector = `input:not([disabled])${classSelector}, select:not([disabled])${classSelector},
        textarea:not([disabled])${classSelector},
        button:not([disabled])${classSelector}, ${classSelector}:not(form):not(div):not(pb-disponents)`;

    const isVisible = (element: HTMLElement) => element.offsetParent !== null;

    if (!Array.isArray(parents)) {
        parents = [parents];
    }

    const sortedParents = parents.sort(compareDOMPositionFunction);
    const focusableElementsGroupedByParents = sortedParents.map(parent =>
        Array.from(parent.querySelectorAll(focusableElementsQuerySelector)).filter(isVisible),
    );
    const focusableElements = [].concat.apply([], focusableElementsGroupedByParents);
    let firstFocusableElement = focusableElements[0];
    if (firstFocusableElement && firstFocusableElement.focus) {
        // try to find form-group
        let formGroupElement = firstFocusableElement;
        let counter = 6;
        let labelSize = 37;
        while (counter > 0 && formGroupElement.parentElement) {
            if (hasClass(formGroupElement, 'form-group')) {
                firstFocusableElement = formGroupElement;
                labelSize = 0;
                break;
            } else {
                formGroupElement = formGroupElement.parentElement;
                counter--;
            }
        }

        scrollIntoElementStart(firstFocusableElement, -labelSize - GLOBAL_OFFSET);

        setTimeout(() => firstFocusableElement.focus(), 500);
    }
}

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function splitTelephoneNumber(tel: string, group: FormGroup) {
    const telephoneData = {
        telephoneNumber: (tel || '').substring(4),
        telephonePrefix: (tel || '').substring(0, 4),
    };
    group.patchValue(telephoneData);
}

export function scrollIntoElementStart(elementRef: ElementRef, offset: number = 0) {
    const nativeElement = elementRef instanceof ElementRef ? elementRef.nativeElement : elementRef;
    if (!!nativeElement) {
        const position = dw_getPageOffsets(nativeElement);

        animateScrollTo(position.y + offset);
    }
}

export function scrollIntoCenter(offset: number = 0) {
    animateScrollTo((window.innerHeight - window.outerHeight) / 2 + offset);
}
export function scrollIntoCenterDelay(offset: number = 0) {
    setTimeout(() => scrollIntoCenter(offset), 300);
}

export function scrollIntoElementStartDelayed(elementRef: ElementRef) {
    setTimeout(() => {
        scrollIntoElementStart(elementRef);
    }, 300);
}

/**
 * riesi problem na android zariadeniach
 */
export function makeBlurGreatAgain() {
    if (document.activeElement && 'blur' in document.activeElement) {
        (document.activeElement as any).blur();
    } else {
        document.body.focus();
    }
}

/**
 * safely decrement value not less than zero
 * @param value
 * @returns
 */
export function decrement(value) {
    return Math.max(value - 1, 0);
}

/**
 * scroll na vrch obrazovky
 * je to dobre volat pri prechode medzi strankami aby sme resetovali polohu scrollu
 */
export function scrollUp(): void {
    window.scrollTo(0, 0);
}

export function dw_getPageOffsets(el) {
    const sOff = dw_getScrollOffsets();
    let left = 0;
    let top = 0;
    let props;

    if (el.getBoundingClientRect) {
        props = el.getBoundingClientRect();
        left = props.left + sOff.x;
        top = props.top + sOff.y;
    } else {
        // for older browsers
        do {
            left += el.offsetLeft;
            top += el.offsetTop;
            // tslint:disable-next-line: no-conditional-assignment
        } while ((el = el.offsetParent));
    }
    return {
        x: Math.round(left),
        y: Math.round(top),
        height: el.clientHeight,
        width: el.clientWidth,
    };
}

export function dw_getScrollOffsets() {
    const doc = document;
    const w = window;
    let x;
    let y;
    let docEl;

    if (typeof w.pageYOffset === 'number') {
        x = w.pageXOffset;
        y = w.pageYOffset;
    } else {
        docEl = doc.compatMode && doc.compatMode === 'CSS1Compat' ? doc.documentElement : doc.body;
        x = docEl.scrollLeft;
        y = docEl.scrollTop;
    }
    return { x, y };
}

export function stripQueryParams(url: string): string {
    return url.split('?')[0];
}

export function toggleControl(control, shouldBeEnabled) {
    if (control.enabled !== shouldBeEnabled) {
        if (shouldBeEnabled) {
            control.enable();
        } else {
            control.disable();
        }
    }
}

export function roundNumber(num: number) {
    return Math.round(num * 100) / 100;
}

export function vyjebatNullProperty(obj) {
    return Object.keys(obj).reduce((result, key) => {
        if (isValue(obj[key])) {
            result[key] = obj[key];
        }
        return result;
    }, {});
}

export function isObject(val) {
    if (val === null) {
        return false;
    }
    return typeof val === 'function' || typeof val === 'object';
}

export function downloadBlob(blob, fileName, newTab) {
    if (window.navigator && window.navigator['msSaveOrOpenBlob']) {
        window.navigator['msSaveOrOpenBlob'](blob, fileName);
    } else {
        const blobURL = window.URL.createObjectURL(blob);

        if (newTab) {
            window.open(blobURL, '_blank');
        } else {
            const element = document.createElement('a');
            element.href = blobURL;
            element.download = fileName;
            // document.body.appendChild(element);
            element.click();
        }
    }
}

/**
 * Checks whether user has specific roles
 * @param userRoles user roles
 * @param requiredRoles roles to check
 * @param requiredAll indication whether user require all roles
 */
export function hasRoles(
    userRoles: string[],
    requiredRoles: string | string[],
    negate = false,
    requiredAll?: boolean,
): boolean {
    if (typeof requiredRoles === 'string') {
        requiredRoles = [requiredRoles];
    }

    if (!requiredRoles || requiredRoles.length === 0) {
        return true;
    } else {
        if (!userRoles || userRoles.length === 0) {
            return false;
        }
        if (requiredAll) {
            return requiredRoles.every(role => userRoles.indexOf(role) > -1);
        }

        if (negate) {
            return !requiredRoles.some(role => userRoles.indexOf(role) > -1);
        }

        return requiredRoles.some(role => userRoles.indexOf(role) > -1);
    }
}

export function getComparatorByAttribute(attribute: string, desc = false) {
    return (a, b) => {
        if (a[attribute] < b[attribute]) {
            return desc ? 1 : -1;
        }
        if (a[attribute] > b[attribute]) {
            return desc ? -1 : 1;
        }

        return 0;
    };
}

export function getRandomLorem(minLength: number, maxLength: number) {
    const start = Math.round(Math.random() * (LOREM_IPSUM.length - minLength));
    const length = Math.round(Math.random() * (maxLength - minLength) + minLength);
    return LOREM_IPSUM.substr(start, length);
}

export function mergeTwoSortedObjectArrays<T1, T2>(arr1: T1[], arr2: T2[], sortedBy: string) {
    const merged: (T1 | T2)[] = [];
    let index1 = 0;
    let index2 = 0;

    for (let current = 0; current < arr1.length + arr2.length; current++) {
        const isArr1Depleted = index1 >= arr1.length;
        const isArr2Depleted = index2 >= arr2.length;

        if (!isArr1Depleted && (isArr2Depleted || arr1[index1][sortedBy] <= arr2[index2][sortedBy])) {
            merged.push(arr1[index1]);
            index1++;
        } else {
            merged.push(arr2[index2]);
            index2++;
        }
    }

    return merged;
}

export function getDigitsCount(n: number) {
    // tslint:disable-next-line:no-bitwise
    return (Math.log(n) * Math.LOG10E + 1) | 0;
}

export function diffObject(obj1, obj2) {
    // Make sure an object to compare is provided
    if (!obj2 || Object.prototype.toString.call(obj2) !== '[object Object]') {
        return obj1;
    }

    //
    // Variables
    //

    const diffs = {};

    //
    // Methods
    //

    /**
     * Check if two arrays are equal
     * @param  {Array}   arr1 The first array
     * @param  {Array}   arr2 The second array
     * @return {Boolean}      If true, both arrays are equal
     */
    const arraysMatch = (arr1, arr2) => {
        // Check if the arrays are the same length
        if (arr1.length !== arr2.length) return false;

        // Check if all items exist and are in the same order
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) return false;
        }

        // Otherwise, return true
        return true;
    };

    /**
     * Compare two items and push non-matches to object
     * @param  {*}      item1 The first item
     * @param  {*}      item2 The second item
     * @param  {String} key   The key in our object
     */
    const compare = (item1, item2, key) => {
        // Get the object type
        const type1 = Object.prototype.toString.call(item1);
        const type2 = Object.prototype.toString.call(item2);

        // If type2 is undefined it has been removed
        if (type2 === '[object Undefined]') {
            diffs[key] = null;
            return;
        }

        // If items are different types
        if (type1 !== type2) {
            diffs[key] = item2;
            return;
        }

        // If an object, compare recursively
        if (type1 === '[object Object]') {
            const objDiff = diffObject(item1, item2);
            if (Object.keys(objDiff).length > 0) {
                diffs[key] = objDiff;
            }
            return;
        }

        // If an array, compare
        if (type1 === '[object Array]') {
            if (!arraysMatch(item1, item2)) {
                diffs[key] = item2;
            }
            return;
        }

        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (type1 === '[object Function]') {
            if (item1.toString() !== item2.toString()) {
                diffs[key] = item2;
            }
        } else {
            if (item1 !== item2) {
                diffs[key] = item2;
            }
        }
    };

    //
    // Compare our objects
    //

    // Loop through the first object
    for (const key in obj1) {
        if (obj1.hasOwnProperty(key)) {
            compare(obj1[key], obj2[key], key);
        }
    }

    // Loop through the second object and find missing items
    for (const key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (!obj1[key] && obj1[key] !== obj2[key]) {
                diffs[key] = obj2[key];
            }
        }
    }

    // Return the object of differences
    return diffs;
}

export function _localeFactory() {
    const browserLang = window.navigator.language || 'en-GB'; // fallback English
    let locale = browserLang;

    switch (browserLang.toLocaleLowerCase()) {
        case 'de':
        case 'de-de':
        case 'de-ch':
        case 'de-at':
        case 'de-it':
            break;
        // case 'sk':
        // case 'sk-sk':
        //     return browserLang;
        default:
            locale = 'en-GB';
    }

    moment.locale(locale);
    return locale;
}

/**
 * Converts [ngInput] or classic HTML attribute to boolean value.
 * Main purpose is to have ability to input classic JS expression via e.g. [disabled] but also
 * the HTML way with simple attribute e.g. disabled, where true is empty attribute,
 * For e.g. attribute disabled:
 *      true: disabled | disabled="true" | disabled="disabled" | disabled="any value" | [disabled]="true"
 *      false: no attribute | disabled="false" | [disabled]="false" | [disabled]
 * @example:
 *      @Input() public set disabled(val:any) { this._disabled = toBoolean(val); }
 * Value table:
 *      definition              input                 output
 *      -----------------------------------------------------
 *      [disabled]            -> no input          ->  no output (default value)
 *      [disabled]=""         -> no input          ->  no output (default value)
 *      -----------------------------------------------------
 *      disabled="false"      -> string "false"    ->  false
 *      [disabled]="false"    -> boolean false     ->  false
 *      [disabled]="......."  -> whatever javascript falsy value ->  false
 *      -----------------------------------------------------
 *      disabled              -> string ""         ->  true
 *      disabled=""           -> string ""         ->  true
 *      disabled="true"       -> string "true"     ->  true
 *      disabled="disabled"   -> string "disabled" ->  true
 *      disabled="whatever"   -> string "whatever" ->  true
 *      [disabled]="true"     -> boolean true      ->  true
 *      [disabled]="......."  -> whatever javascript thruthy value ->  true
 *                              (only exception is empty string, which is true due
 *                               accepting solo disabled and disabled="" as true)
 */
export function toBoolean(val: any) {
    // checks all falsy js values (false, undefined, null, 0, NaN) except empty string ("") and as falsy is accepted also string "false"
    return (
        val !== false &&
        val !== 'false' &&
        val !== undefined &&
        val !== null &&
        val !== 0 &&
        val === val /* val===val is check for NaN (it does not equal itself)*/
    );
}

/**
 *
 * @param val boolean
 * @returns return '1' if `val` is true otherwise '0'
 */
export function boolToApi(val: boolean) {
    return val ? '1' : '0';
}
export function buildReplicaUrl({
    projectId,
    tokenizedHtmlHash,
    taskId,
    scrollTop,
    scrollLeft,
    /** To highlight selected elements. */
    selectors = [],
    pins = [],
    dateTime,
    clusterDateTime,
    includeUnpublished,
    id = null,
}: ReplicaProps) {
    const queryParams: ReplicaURLProps = {
        projectId,
        html: tokenizedHtmlHash,
        ...(taskId ? { taskId } : {}),
        scrollTop,
        scrollLeft,
        ...(dateTime ? { dateTime: dateTime } : {}),
        ...(includeUnpublished ? { includeUnpublished: 1 } : {}),
        ...(clusterDateTime ? { clusterDateTime: clusterDateTime } : {}),
        ...(selectors.length > 0 ? { selectors: encodeURIComponent(Base64.encode(JSON.stringify(selectors))) } : {}),
        ...(pins.length > 0 ? { pins: encodeURIComponent(Base64.encode(JSON.stringify(pins))) } : {}),
        ...(id !== null ? { id } : {}),
    };
    const buildUrl = () => {
        const queryParamKeys = Object.keys(queryParams);
        const queryParamsString = queryParamKeys
            .filter(key => queryParams[key] !== undefined)
            .map(key => `${key}=${queryParams[key]}`)
            .join('&');

        return environment.replicaUrl + '?' + queryParamsString;
    };

    return buildUrl();
}

/**
 *
 * Show replica as is published (global)
 *
 * case **A**
 *
 * current public view - current published global modifications (with current global CSS style).
 */
export function buildReplicaUrlCurrentPublished({
    projectId,
    tokenizedHtmlHash,
    scrollLeft,
    scrollTop,
    selectors = [],
    pins = [],
}) {
    return buildReplicaUrl({
        projectId,
        tokenizedHtmlHash,
        scrollLeft,
        scrollTop,
        selectors,
        pins,
    });
}

/**
 * ### Show replica as is published (cluster)
 *
 * case **B**
 *
 * when previewing standalone task with its modifications (from cluster) and also with all global modifications too (only published modifications).
 *
 * https://docs.google.com/document/d/1TzR3ObfF3ocHT3fESNj2JKEKIsL4goDCkYHiBV2D0oo/edit?pli=1#bookmark=id.nnf68bfn13uz
 */
export function buildReplicaUrlCurrentTaskPulished({
    taskId,
    projectId,
    tokenizedHtmlHash,
    scrollLeft,
    scrollTop,
    selectors = [],
    pins = [],
}) {
    return buildReplicaUrl({
        projectId,
        tokenizedHtmlHash,
        scrollLeft,
        scrollTop,
        taskId,
        selectors,
        pins,
    });
}

/**
 * ### Show replica as snapshotted
 *
 * case **C**
 *
 * show HTML in state, in which the customer will see it right now that means with all global modifications OR when current task is standalone only its cluster
 *
 * https://docs.google.com/document/d/1TzR3ObfF3ocHT3fESNj2JKEKIsL4goDCkYHiBV2D0oo/edit?pli=1#bookmark=id.ow0yuhxhp13d
 */
export function buildReplicaUrlAtSnapshot({
    dateTime,
    taskId,
    projectId,
    tokenizedHtmlHash,
    scrollLeft,
    scrollTop,
    selectors = [],
    pins = [],
}) {
    return buildReplicaUrl({
        projectId,
        tokenizedHtmlHash,
        scrollLeft,
        scrollTop,
        taskId,
        dateTime,
        selectors,
        pins,
    });
}

/**
 * ### Show replica of task in progress
 *
 * case **D**
 *
 * shows HTML in actual (in progress) state with all unpublished/deleted modifications from the task cluster. (param dateTime represents time of HTML snapshot was created)
 *
 * https://docs.google.com/document/d/1TzR3ObfF3ocHT3fESNj2JKEKIsL4goDCkYHiBV2D0oo/edit?pli=1#bookmark=id.jd1w92pxge8g
 */
export function buildReplicaUrlTaskInProgress({
    snapshotDateTime,
    taskId,
    projectId,
    tokenizedHtmlHash,
    scrollLeft,
    scrollTop,
    selectors = [],
    pins = [],
    id = null,
}) {
    return buildReplicaUrl({
        projectId,
        tokenizedHtmlHash,
        scrollLeft,
        scrollTop,
        taskId,
        dateTime: snapshotDateTime,
        includeUnpublished: true,
        selectors,
        pins,
        id,
    });
}

/**
 * ### Show replica of task at time of completion
 * (same as styler saw when completing)
 *
 * case **E**
 *
 * shows HTML in same state as styler saw in moment when he completed the task, including all unpublished modifications from its task cluster and with all global modifications too. (param dateTime represents time of HTML snapshot was created and param clusterDateTime represents time when the last state “in progress” was ended)
 *
 *
 * https://docs.google.com/document/d/1TzR3ObfF3ocHT3fESNj2JKEKIsL4goDCkYHiBV2D0oo/edit?pli=1#bookmark=id.agidlf2rwu0l
 */
export function buildReplicaUrlTaskCompleted({
    completedDateTime,
    snapshotDateTime,
    taskId,
    projectId,
    tokenizedHtmlHash,
    scrollLeft,
    scrollTop,
    selectors = [],
    pins = [],
}) {
    return buildReplicaUrl({
        projectId,
        tokenizedHtmlHash,
        scrollLeft,
        scrollTop,
        taskId,
        dateTime: snapshotDateTime,
        clusterDateTime: completedDateTime,
        includeUnpublished: true,
        selectors,
        pins,
    });
}
