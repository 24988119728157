<textarea
    class="message-input"
    type="text"
    cdkTextareaAutosize
    #autosize="cdkTextareaAutosize"
    cdkAutosizeMinRows="1"
    cdkAutosizeMaxRows="4"
    placeholder="Enter Message..."
    [(ngModel)]="text"
></textarea>
<button
    #submit
    class="send-btn"
    type="button"
    (click)="sendMessage()"
><i class="send-icon"></i></button>