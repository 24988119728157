import { Component, OnInit, ViewEncapsulation, Input, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmWindowOptions, ConfirmWindowService } from './confirm-window.service';

@Component({
    selector: 'ui-confirm-window',
    templateUrl: './confirm-window.component.html',
    styleUrls: ['./confirm-window.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ConfirmWindowComponent implements OnInit {
    @Input() question: string;

    @Input() options: ConfirmWindowOptions;

    constructor(public modalCtrl: ConfirmWindowService, @Inject(MAT_DIALOG_DATA) data) {
        this.options = data.options;
        this.question = data.question;
    }

    ngOnInit() {}

    close() {
        this.modalCtrl.close();
    }

    yes() {
        this.modalCtrl.close(true);
    }

    no() {
        this.modalCtrl.close(false);
    }
}
