import { HttpClient, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError, timer } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Credentials, JwtToken } from '../../../../shared/types/auth.types';
import { Response } from '../../../../shared/types/http-response.types';
import mixpanel from '../../common/mixpanel';
import { CurrentUserService } from '../current-user.service';
import { JwtDecodeService } from '../jwt-decode.service';
import { environment } from './../../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    private _accessToken = '';
    private refreshTokenTimer: ReturnType<typeof setTimeout>;

    constructor(
        private http: HttpClient,
        private currentUserService: CurrentUserService,
        private jwtDecodeService: JwtDecodeService,
    ) {}

    private set accessToken(accessToken: string) {
        if (accessToken) {
            const decodedToken = this.jwtDecodeService.decodeAccessToken(accessToken);
            this.currentUserService.setCurrentUser({
                roles: decodedToken.roles,
                subroles: decodedToken.subroles,
                id: decodedToken.sub,
            });
        } else {
            this.currentUserService.setCurrentUser(null);
        }

        this._accessToken = accessToken;
    }

    private get accessToken(): string {
        return this._accessToken;
    }

    getAccessToken(): string {
        return this.accessToken;
    }

    isLoggedIn(): Observable<boolean> {
        if (this.accessToken) {
            return of(true);
        } else {
            return this.isRefreshTokenValid();
        }
    }

    logIn(credentials: Credentials): Observable<Response<JwtToken>> {
        return this.http.post<Response<JwtToken>>(`${environment.apiUrl}v1/auth/login`, credentials).pipe(
            tap(response => {
                mixpanel.track('Log In');
                this.accessToken = response.data.accessToken;
                localStorage.setItem('refreshToken', response.data.refreshToken);
                this.setRefreshTokenTimer(response.data.accessTokenExpiresIn);
            }),
        );
    }

    logOut(): Observable<Response<{ message: string }>> {
        // TODO remove from all sesions
        return this.http.post<Response<{ message: string }>>(`${environment.apiUrl}v1/auth/logout`, null).pipe(
            tap(
                () => {
                    this.logOutLocal();
                },
                err => {
                    this.logOutLocal();
                    return throwError(err);
                },
            ),
        );
    }

    logOutLocal() {
        mixpanel.track('Log Out');
        mixpanel.reset();
        this.accessToken = '';
        localStorage.removeItem('refreshToken');
        clearTimeout(this.refreshTokenTimer);
    }

    refreshAccessToken(
        refreshToken: string,
    ): Observable<Response<Pick<JwtToken, 'accessToken' | 'accessTokenExpiresIn'>>> {
        if (!refreshToken || refreshToken.length === 0) {
            return throwError('empty refresh token');
        }
        return this.http
            .post<Response<Pick<JwtToken, 'accessToken' | 'accessTokenExpiresIn'>>>(
                `${environment.apiUrl}v1/auth/refresh`,
                {
                    refreshToken,
                },
            )
            .pipe(
                tap(
                    response => {
                        this.accessToken = response.data.accessToken;
                        this.setRefreshTokenTimer(response.data.accessTokenExpiresIn);
                    },
                    err => {
                        return throwError(err);
                    },
                ),
                catchError((err: HttpErrorResponse) => {
                    if (err.status == HttpStatusCode.TooManyRequests) {
                        const retryAfterHeaderValue = parseInt(err.headers.get('Retry-After')) * 1000;
                        const retryAfter = retryAfterHeaderValue ? retryAfterHeaderValue : 1000;
                        const randomOffset = Math.random() * retryAfter * 0.5;
                        return timer(retryAfter + randomOffset).pipe(
                            switchMap(() => {
                                console.log('retrying token refresh');
                                return this.refreshAccessToken(refreshToken);
                            }),
                        );
                    } else {
                        this.logOutLocal();
                    }

                    return throwError(err);
                }),
            );
    }

    private setRefreshTokenTimer(accessTokenExpiresIn: number): void {
        const secondsBeforeExpiration = 60;
        const refreshToken = localStorage.getItem('refreshToken');
        clearTimeout(this.refreshTokenTimer);
        this.refreshTokenTimer = setTimeout(() => {
            this.refreshAccessToken(refreshToken);
        }, (accessTokenExpiresIn - secondsBeforeExpiration) * 1000);
    }

    private isRefreshTokenValid(): Observable<boolean> {
        const refreshToken = localStorage.getItem('refreshToken');
        return refreshToken ? this.refreshAccessToken(refreshToken).pipe(map(val => val != null)) : of(false);
    }
}
