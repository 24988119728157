import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-dropdown',
    templateUrl: 'dropdown.component.html',
    styleUrls: ['./dropdown.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent {
    constructor() {}
}
