import { ValidationsService } from '../../common/components/validations/validations.service';
import { GlobalLoadingIndicatorService } from '../../common/components/global-loading-indicator/global-loading-indicator.service';
import { HtmlSource, TaskDetail } from '../../../../shared/types/task.types';
import {
    Component,
    Input,
    Output,
    ViewEncapsulation,
    EventEmitter,
    OnChanges,
    ChangeDetectionStrategy,
    SimpleChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { ProjectService } from '../../services/project.service';
import { catchError, take } from 'rxjs/operators';
import { ConfirmWindowService } from '../confirm-window/confirm-window.service';

@Component({
    selector: 'scp-site-switch',
    templateUrl: './site-switch.component.html',
    styleUrls: ['./site-switch.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSwitchComponent implements OnChanges {
    @Input() selectedHtmlSource: HtmlSource;
    @Input() editable = true;
    @Input() htmlSources: HtmlSource[];
    @Input() taskDetail: TaskDetail;
    @Input() disabled = false;
    @Input() readonly = false;

    @Output() selectedHtmlSourceChange = new EventEmitter<HtmlSource>();
    @Output() sitemapUpdated = new EventEmitter();

    isEditing = false;

    sitemap = false;
    sitemapName: string;

    constructor(
        private loading: GlobalLoadingIndicatorService,
        private validationsService: ValidationsService,
        private projectService: ProjectService,
        private confirmWindowService: ConfirmWindowService,
        private cdRef: ChangeDetectorRef,
    ) {}

    changeSelectedSource(source: HtmlSource) {
        if (!this.isEditing) {
            this.selectedHtmlSourceChange.emit(source);
            this.selectedHtmlSource = source;
            if (this.editable) {
                // set selected html source when changed
                this.sitemap = source.html.sitemap;
                this.sitemapName = source.html.sitemapName || this.taskDetail.name;
            }
        }
    }

    changeSitemap(sitemap): void {
        this.sitemap = sitemap;
        if (this.selectedHtmlSource.html.sitemap !== sitemap) {
            if (sitemap) {
                this.edit();
            } else {
                // removing from sitemap
                this.confirmWindowService
                    .open('Do you want remove this snapshot from Sitemap?', {
                        negativeAction: true,
                    })
                    .pipe(take(1))
                    .subscribe(remove => {
                        if (remove) {
                            this.save();
                        } else {
                            this.cancel();
                            this.cdRef.detectChanges();
                        }
                    });
            }
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedHtmlSource && changes.selectedHtmlSource.currentValue) {
            this.sitemap = !!this.selectedHtmlSource?.html?.sitemap;
            this.sitemapName = this.selectedHtmlSource?.html?.sitemapName || this.taskDetail?.name;
        }
    }

    edit() {
        this.isEditing = true;
    }

    save() {
        if (this.editable) {
            this.loading.startLoad();

            const newTaskHtml = {
                ...this.selectedHtmlSource.html,
                sitemap: this.sitemap,
                sitemapName: this.sitemapName,
            };

            this.projectService
                .patchSitemap(this.taskDetail.project.id, newTaskHtml)
                .pipe(catchError(this.validationsService.getErrorHandler()))
                .subscribe(() => {
                    // emit new taskHtml
                    this.selectedHtmlSourceChange.emit({
                        ...this.selectedHtmlSource,
                        html: newTaskHtml,
                    });
                    this.sitemapUpdated.emit();
                    this.loading.endLoad();
                    this.isEditing = false;
                });
        }
    }

    cancel() {
        this.sitemap = this.selectedHtmlSource.html.sitemap;
        this.sitemapName = this.selectedHtmlSource.html.sitemapName;

        this.isEditing = false;
    }
}
