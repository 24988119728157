import { isValue } from 'src/app/common';
import { InjectionToken } from '@angular/core';
import { Conflict, MergeHunkDetail, TaskConflict } from './editor.types';
import { Base64 } from 'js-base64';

export const MONACO_EDITOR_CONFIG = new InjectionToken('SCP_MONACO_EDITOR_CONFIG');

export const CONFLICT_TAGS = {
    start: '// <<<<<<< CURRENT',
    separator: '// =======',
    end: '// >>>>>>> INCOMING',
};

export function decodeMergeHunks(conflict: TaskConflict): TaskConflict {
    (conflict.conflicts || []).forEach(c =>
        (c.mergeHunks || []).forEach(hunk => {
            decodeHunkDetailSafe(hunk.base);
            decodeHunkDetailSafe(hunk.source);
            decodeHunkDetailSafe(hunk.destination);
        }),
    );

    return conflict;

    function decodeHunkDetailSafe(hunkDetail: MergeHunkDetail) {
        if (hunkDetail) {
            hunkDetail.hunkContent = Base64.decode(hunkDetail.hunkContent);
        }
    }
}

export function getConflictContent(conflict: Conflict) {
    const lines = conflict.mergeHunks.flatMap(mh => {
        if (mh.isConflict) {
            return [
                CONFLICT_TAGS.start,
                ...hunkToLines(mh.destination?.hunkContent),
                CONFLICT_TAGS.separator,
                ...hunkToLines(mh.source?.hunkContent),
                CONFLICT_TAGS.end,
            ];
        }

        const base = splitSafe(mh.base?.hunkContent, '\n');
        const destination = splitSafe(mh.destination?.hunkContent, '\n'); // ours
        const source = splitSafe(mh.source?.hunkContent, '\n'); // theirs
        const result: string[] = [];

        if (base && !destination && !source) {
            if (base.length > 1 && base[base.length - 1] === '') {
                base.pop(); // splitting is adding empty line so we need to remove onem
            }
            result.push(...base);
        }
        if (destination) {
            if (destination.length > 1 && destination[destination.length - 1] === '') {
                destination.pop(); // splitting is adding empty line so we need to remove onem
            }
            result.push(...destination);
        }
        if (source) {
            if (source.length > 1 && source[source.length - 1] === '') {
                source.pop(); // splitting is adding empty line so we need to remove onem
            }
            result.push(...source);
        }
        return result;
    });

    return lines;
}

export function splitSafe(content: string, separator: string) {
    if (content === '') {
        return [];
    }

    if (!isValue(content)) {
        return null;
    }

    return content.split(separator);
}

export function getCodeLines(content: string) {
    if (content === null || content === undefined) {
        return null;
    }

    const codeLines = splitSafe(content, '\n');

    if (codeLines.length > 1 && codeLines[codeLines.length - 1] === '') {
        codeLines.pop(); // splitting is adding empty line so we need to remove onem
    }

    return codeLines;
}

function hunkToLines(hunkContent: string): string[] {
    const rawLines = splitSafe(hunkContent, '\n');
    if (rawLines?.length > 0 && rawLines[rawLines.length - 1] == '') {
        return rawLines.slice(0, -1) || [''];
    }
    return rawLines || [''];
}
