export interface User {
    id: string;
    nickname: string;
    email: string;
    roles?: UserRoles[];
    subroles?: SubRoles[];
}

export type UserRef = Pick<User, 'id' | 'nickname'>;

export interface UserDetail extends User {
    state: UserState;
    roles: UserRoles[];
    subroles: SubRoles[];
    isStyler: boolean; // calculated
    isOnlyStyler: boolean; //calculated
    isTaskManager: boolean; // calculated
    isSuperUserOrAdmin: boolean; // calculated
    isCodeReviewer: boolean; // calculated
    createdDatetime: string;
    lastLoggedInDatetime: string;
    /** price in cents */
    stylerRate?: number;
    stylerEfficiency?: number;
    stylingLevel: StylingLevel;
    notes: string;
    wageSum?: number;
}

export interface CreateUserDetail {
    email: string;
    password: string;
    nickname?: string;
    state?: UserState;
    stylerRate?: number;
    stylerEfficiency?: number;
    stylingLevel?: StylingLevel;
    notes: string;
    roles: UserRoles[];
    subroles: SubRoles[];
}

export interface CreateUserStylerAnonymous {
    email: string;
    password: string;
    nickname: string;
}

export interface UserListFilter {
    project?: string[];
    subrole?: SubRoles[]; // for mode styler only
}

// export interface StylerUserDetail extends UserDetail {
//     roles: [UserRoles.styler, UserRoles];
//     subroles: StylerSubRoles;
// }

export enum UserState {
    active = 'active',
    notConfirmed = 'not confirmed',
}

export enum UserRoles {
    admin = 'admin',
    superUser = 'superuser',
    styler = 'styler',
    customer = 'customer',
}

export enum SubRoles {
    taskManager = 'task manager',
    codeReviewer = 'code reviewer',
    styler = 'styler',
    assetManager = 'asset manager',
    support = 'support',
    guru = 'guru',
}

export enum StylingLevel {
    newbie = 'newbie',
    junior = 'junior',
    medior = 'medior',
    senior = 'senior',
    guru = 'guru',
}

export const SubRolesOptions = Object.values(SubRoles);
export const StylingLevelOptions = Object.values(StylingLevel);
