import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    CanActivateChild,
    CanLoad,
    Route,
    Router,
    RouterStateSnapshot,
    UrlSegment,
    UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '../../services/auth/auth.service';
import { CurrentUserService } from '../../services/current-user.service';

@Injectable({
    providedIn: 'root',
})
export class MoreThanStylerGuard implements CanLoad, CanActivate, CanActivateChild {
    constructor(public auth: AuthService, public currentUserService: CurrentUserService, public router: Router) {}

    private can() {
        return this.auth.isLoggedIn().pipe(
            map(loggedIn => {
                if (!loggedIn) {
                    return this.router.parseUrl('/new/login');
                }
                if (this.currentUserService.getCurrentUser().isOnlyStyler) {
                    return this.router.parseUrl('/new');
                } else {
                    return true;
                }
            }),
        );
    }

    canLoad(
        route: Route,
        segments: UrlSegment[],
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.can();
    }

    canActivate() {
        return this.can();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.can();
    }
}
