import { NgModule } from '@angular/core';
import { MixpanelDirective, MixpanelClickDirective } from './mixpanel.directive';

const components = [MixpanelDirective, MixpanelClickDirective];

@NgModule({
    declarations: [...components],
    exports: [...components],
    providers: [],
})
export class MixpanelModule {}
