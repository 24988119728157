import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    Host,
    Input,
    OnDestroy,
    Optional,
    SkipSelf,
} from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ErrorMessage, ValidationsService } from './validations.service';

@Component({
    selector: 'scp-validations',
    templateUrl: './validations.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationsComponent implements OnDestroy, DoCheck {
    @Input() of: string;
    @Input() errorMessages: { [key: string]: ErrorMessage | ((_?: any) => {}) } = {};
    @Input() customControl = null;

    errors: Array<{ message: ErrorMessage; code: string }> = [];

    destroy$: Subject<boolean> = new Subject<boolean>();

    touched: boolean;
    theControl: AbstractControl;

    constructor(
        private cd: ChangeDetectorRef,
        private validationsService: ValidationsService,
        @Optional()
        @Host()
        @SkipSelf()
        private parent?: ControlContainer,
    ) {}

    ngDoCheck() {
        if (this.theControl && this.theControl.touched && !this.touched) {
            this.touched = true;
            this.theControl.updateValueAndValidity();
        } else if (this.theControl && !this.theControl.touched && this.touched) {
            this.touched = false;
        }

        if (this.parent && !this.theControl) {
            this.theControl = this.parent.control.get(this.of);
            this.attach();
        }

        if (this.customControl && !this.theControl) {
            this.theControl = this.customControl.control;
            this.attach();
        }
    }

    attach() {
        if (this.theControl) {
            this.theControl.statusChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
                if (this.theControl && !this.theControl.valid) {
                    this.errors = Object.keys(this.theControl.errors || {}).map(errorKey => {
                        if (this.theControl.errors[errorKey].message) {
                            // ak pride chybova hlaska zo servera
                            return {
                                code: '',
                                message: { key: this.theControl.errors[errorKey].message },
                            };
                        }
                        let errorMessage;
                        const errorMessageTemplate =
                            (this.errorMessages && this.errorMessages[errorKey]) ||
                            this.validationsService.errorMessages[errorKey];
                        if (_.isFunction(errorMessageTemplate)) {
                            errorMessage = errorMessageTemplate(this.theControl.errors[errorKey]);
                        } else {
                            errorMessage = errorMessageTemplate;
                        }

                        return {
                            message: errorMessage,
                            code: errorKey,
                        };
                    });
                } else {
                    this.errors = [];
                }

                this.cd.markForCheck();
            });
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
