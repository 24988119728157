import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'duration',
})
export class DurationPipe implements PipeTransform {
    constructor() {}

    /**
     * Convert to human readable duration e.g. "3h 24m".
     * @param duration Duration (number) in seconds.
     * @param includeSeconds Output also seconds.
     * @param decimal If prefered output is decimal number in hours e.g. "3.45h"
     */
    transform(duration: any, includeSeconds = false, decimal = false, includeDays = false): string {
        let seconds = parseInt(duration, 10);
        if (Number.isNaN(seconds)) return '';

        const sign = seconds < 0 ? '-' : '';
        seconds = Math.abs(seconds);

        const MINUTE = 60;
        const HOUR = 60 * 60;
        const DAY = 60 * 60 * 24;

        if (decimal) return sign + (seconds / HOUR).toFixed(2) + 'h';

        const days = Math.floor(seconds / DAY);
        if (days > 3) {
            return sign + days + 'd';
        }
        if (includeSeconds || includeDays) {
            seconds -= days * DAY;
        }

        const hours = Math.floor(seconds / HOUR);
        seconds -= hours * HOUR;

        const minutes = Math.floor(seconds / MINUTE);
        seconds = Math.floor(seconds - minutes * MINUTE);

        // if everything is zero, return zero withot unit
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) return '0';

        if (!includeSeconds) {
            const output: string[] = [];
            if (includeDays && days !== 0) output.push(days + 'd');
            if (hours !== 0) output.push(hours + 'h');
            if (hours !== 0 || minutes !== 0) output.push(minutes + 'm');
            if (hours === 0 && minutes === 0) output.push(seconds + 's');
            return sign + output.join(' ');
        } else {
            // always add seconds:
            //  5m 23s
            //  2h 0m 14s
            //  15s
            //  14h 22m 0s
            //  4d 2h 32m 6s
            const output: string[] = [];
            if (days !== 0) output.push(days + 'd');
            if (days !== 0 || hours !== 0) output.push(hours + 'h');
            if (days !== 0 || hours !== 0 || minutes !== 0) output.push(minutes + 'm');
            output.push(seconds + 's');
            return sign + output.join(' ');
        }
    }
}
