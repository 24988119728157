import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ConfirmWindowComponent } from './confirm-window.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmWindowService {
    private dialogRef: MatDialogRef<ConfirmWindowComponent, boolean>;

    constructor(public dialog: MatDialog) {}

    open(question: string, options: ConfirmWindowOptions = {}) {
        this.dialogRef = this.dialog.open(ConfirmWindowComponent, {
            panelClass: ['ui-confirm-window-modal', options.negativeAction ? 'negative' : ''],
            hasBackdrop: true,
            disableClose: true,
            data: { question, options },
        });

        return this.dialogRef.afterClosed();
    }

    close(data?) {
        this.dialogRef.close(data);
    }
}

export type ConfirmWindowOptions = {
    negativeAction?: boolean;
    description?: string;
};
