import { Directive, HostListener, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import mixpanel from '../mixpanel';

@UntilDestroy()
@Directive({ selector: '[track]' })
export class MixpanelDirective implements OnChanges, OnDestroy {
    @Input() track: string;
    @Input() trackTimeToExit: boolean;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.trackTimeToExit) {
            mixpanel.track(this.track);
        }

        if (changes.trackTimeToExit) {
            mixpanel.time_event(this.track);
        }
    }

    ngOnDestroy() {
        if (this.trackTimeToExit) {
            mixpanel.track(this.track);
        }
    }
}

@UntilDestroy()
@Directive({ selector: '[trackClick]' })
export class MixpanelClickDirective {
    @Input() trackClick: string;
    @HostListener('click', ['$event'])
    onClick(event: Event) {
        mixpanel.track('click_' + this.trackClick, {
            text: (event?.target as HTMLElement)?.innerText,
        });
    }
}
