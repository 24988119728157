import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { decrement } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class GlobalLoadingIndicatorService {
    private loadingItemsdingItems = 0;
    // tslint:disable-next-line: variable-name
    private _numberOfLoadingItems: BehaviorSubject<number> = new BehaviorSubject(this.loadingItemsdingItems);

    public numberOfLoadingItems: Observable<number> = this._numberOfLoadingItems.asObservable();

    constructor() {}

    startLoad() {
        this.loadingItemsdingItems++;
        this.propagateChange();
    }

    endLoad() {
        this.loadingItemsdingItems = decrement(this.loadingItemsdingItems);
        this.propagateChange();
    }

    stopAll() {
        this.loadingItemsdingItems = 0;

        this.propagateChange();
    }

    isLoading() {
        return this.loadingItemsdingItems > 0;
    }

    propagateChange() {
        this._numberOfLoadingItems.next(this.loadingItemsdingItems);
    }
}
