import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';

/**
 *
 * Preview HTML from time of snapshot
 *
 */
@Component({
    selector: 'scp-html-preview',
    templateUrl: './html-preview.component.html',
    styleUrls: ['./html-preview.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlPreviewComponent implements AfterViewInit, OnChanges {
    @Input()
    width = 1280;

    @Input()
    height = 800;

    @Input()
    replicaUrl: string;

    // to ability to refresh from outside (because "OnPush")
    @Input()
    salt: any;

    @ViewChild('siteReplica') iframe: ElementRef<HTMLIFrameElement>;

    viewportWidth = 0;
    viewportHeight = 0;
    viewportKoef = 1; // width / height

    zoom = 1;

    // https://developapa.com/angular-rerender/
    @ViewChild('outlet', { read: ViewContainerRef }) outletRef: ViewContainerRef;
    @ViewChild('content', { read: TemplateRef }) contentRef: TemplateRef<any>;

    constructor(private element: ElementRef, private change: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.calculateViewportSize();
    }

    ngOnChanges(changes: SimpleChanges) {
        // this.iframe.nativeElement.contentDocument.location.replace(this.replicaUrl);
        // hack for reading the viewport size
        // (because its container is resizing /higher layout is changing/
        //  on the fly, but this component uses OnPush strategy)
        this.rerender();
        setTimeout(() => this.calculateViewportSize(), 100);
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.calculateViewportSize();
    }

    calculateViewportSize() {
        this.viewportWidth = this.element.nativeElement.offsetWidth;
        this.viewportHeight = this.element.nativeElement.offsetHeight;
        this.viewportKoef = this.viewportWidth / this.viewportHeight;
        this.recalculate();
    }

    recalculate() {
        const koef = this.width / this.height;
        if (koef < this.viewportKoef) {
            this.zoom = this.viewportHeight / this.height;
        } else {
            this.zoom = this.viewportWidth / this.width;
        }
        // to make padding
        this.zoom *= 0.92;
        this.change.markForCheck();
        // this.change.detectChanges();
    }

    getTransform() {
        return `scale(${this.zoom.toFixed(8)})`;
    }

    public rerender() {
        this.outletRef?.clear();
        this.outletRef?.createEmbeddedView(this.contentRef);
    }
}
