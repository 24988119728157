import { LayoutComponent } from './layout/layout.component';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { PageNotFoundComponent } from './common/components/page-not-found/page-not-found.component';
import { AuthGuardService } from './common/guards/auth.guard';
import { MoreThanStylerGuard } from './common/guards/more-than-styler.guard';

const routes: Routes = [
    {
        path: '',
        canActivateChild: [AuthGuardService],
        component: LayoutComponent,
        children: [
            {
                path: '',
                redirectTo: 'tasks',
                pathMatch: 'full',
            },
            {
                path: 'tasks',
                canLoad: [MoreThanStylerGuard],
                canActivate: [MoreThanStylerGuard],
                canActivateChild: [MoreThanStylerGuard],
                loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksPageModule),
            },
            {
                path: 'editor',
                loadChildren: () => import('./editor/editor.module').then(m => m.EditorPageModule),
            },
            {
                path: 'code-review',
                loadChildren: () => import('./code-review/code-review.module').then(m => m.CodeReviewModule),
            },
            {
                // canLoad: [MoreThanStylerGuard],
                // canActivate: [MoreThanStylerGuard],
                // canActivateChild: [MoreThanStylerGuard],
                path: 'turns',
                loadChildren: () => import('./turns/turns.module').then(m => m.TurnsModule),
            },
            {
                canLoad: [MoreThanStylerGuard],
                canActivate: [MoreThanStylerGuard],
                canActivateChild: [MoreThanStylerGuard],
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
            },
            {
                canLoad: [MoreThanStylerGuard],
                canActivate: [MoreThanStylerGuard],
                canActivateChild: [MoreThanStylerGuard],
                path: 'projects',
                loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
            },
        ],
    },
    {
        path: 'site-replica',
        loadChildren: () => import('./site-replica/site-replica.module').then(m => m.SiteReplicaPageModule),
    },
    {
        path: 'new',
        loadChildren: () => import('./new-pages/new-pages.module').then(m => m.NewPagesModule),
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
    {
        path: 'not-found',
        component: PageNotFoundComponent,
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            relativeLinkResolution: 'legacy',
        }),
        HttpClientModule,
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
