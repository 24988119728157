import { environment } from './../../../environments/environment';
import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'ui-env',
    templateUrl: './env.component.html',
    styleUrls: ['./env.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvComponent {
    @HostBinding('attr.type')
    type = environment.env;

    @HostBinding('attr.title')
    get title() {
        return 'Current environment is ' + this.type.toUpperCase() + '.';
    }

    constructor() {}

    getEnvShortcut() {
        return this.type.length <= 3 ? this.type : this.type.replace(/[aeiouy]/gi, '');
    }
}
