import { _localeFactory } from './utils';
import * as moment from 'moment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { Injectable } from '@angular/core';

@Injectable()
export class SemDateAdapter extends MomentDateAdapter {
    constructor() {
        super(_localeFactory()); // set default locale

        moment.fn.toJSON = function () {
            return this.toISOString(true).substr(0, 10);
        };
    }
}
