import moment from 'moment';
import { MomentInput } from 'moment';

export function todayISODate(): string {
    return moment().toISOString(true).substr(0, 10);
}

export function tomorrowISODate(): string {
    return fromTodayISODate(1);
}

export function fromTodayISODate(count: number, unit = 'd'): string {
    return addToDateISODate(moment(), count, unit);
}

export function addToDateISODate(date, count: number, unit = 'd'): string {
    return moment(date)
        .add(count as any, unit)
        .toISOString(true)
        .substr(0, 10);
}

export function isDateFromInterval(dateToCheck: MomentInput, startOfInterval: MomentInput, endOfInterval: MomentInput) {
    if (!startOfInterval && !endOfInterval) {
        return false;
    }

    if (startOfInterval && !endOfInterval) {
        return moment(dateToCheck).isSameOrAfter(startOfInterval);
    }

    if (endOfInterval && !startOfInterval) {
        return moment(dateToCheck).isBefore(endOfInterval);
    }

    return moment(dateToCheck).isBetween(startOfInterval, endOfInterval, null, '[)');
}

export function getHoursArray() {
    const hoursArray = [];
    for (let i = 0; i < 24; i++) {
        const hour = (i < 10 ? '0' : '') + i + ':00';
        hoursArray.push({
            value: hour + ':00',
            label: hour,
        });
    }
    return hoursArray;
}

export function getWeekDaysArray() {
    const daysArray = [];
    for (let i = 1; i <= 7; i++) {
        daysArray.push({
            value: i,
            label: moment()
                .startOf('week')
                .add(i - 1, 'days')
                .format('dddd'),
        });
    }
    return daysArray;
}

export function getMonthDaysArray() {
    const monthDays = [];
    for (let i = 1; i <= 28; i++) {
        monthDays.push({
            value: i,
            label: i,
        });
    }
    return monthDays;
}

export function toISODate(date: any) {
    if (date) {
        return moment(date).toISOString(true).substr(0, 10);
    } else {
        return null;
    }
}

export function toISODateTime(date: any) {
    if (date) {
        return moment(date).toISOString(true);
    } else {
        return null;
    }
}

/** Return duration (in sec) from given date. */
export const timeFromStart = (date: string | number | Date): number => {
    const now = Date.now();
    const from = new Date(date).getTime();
    return Math.round((now - from) / 1000);
};

export const diffDateInSeconds = (from: string | Date, to: string | Date) => {
    const fromTime = new Date(from).getTime();
    const toTime = new Date(to).getTime();
    return Math.round((toTime - fromTime) / 1000);
};

export function addHours(date: Date, h: number) {
    date.setTime(date.getTime() + h * 60 * 60 * 1000);
    return date;
}
