import {
    API_URL_DEV,
    CDN_URL_DEV,
    REPLICA_URL_DEV,
    ASSET_MANAGER_DEV,
    EDITOR_URL_DEV,
} from '../../shared/types/constants.types';

export const environment = {
    env: 'dev',
    production: true,
    apiUrl: API_URL_DEV,
    cdnUrl: CDN_URL_DEV,
    replicaUrl: REPLICA_URL_DEV,
    assetManagerUrl: ASSET_MANAGER_DEV,
    spotEditorUrl: EDITOR_URL_DEV,
};
