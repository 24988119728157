import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { SubRoles, UserRoles } from '../../../shared/types/user.types';

@Injectable({
    providedIn: 'root',
})
export class JwtDecodeService {
    constructor() {}

    decodeAccessToken(accessToken: string) {
        return jwt_decode<DecodedAccessToken>(accessToken);
    }
}

export interface DecodedAccessToken {
    sub: string;
    iss: string;
    aud: string;
    exp: number;
    iat: number;
    token_use: string;
    roles: UserRoles[];
    subroles: SubRoles[];
    logout_id: string;
}
