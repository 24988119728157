import { Component, HostBinding, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { toBoolean } from '../../common';
import { GenericAlignAndValignAndBlockComponent } from './generic-align-and-valign';

@Component({
    selector: 'ui-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LayoutComponent extends GenericAlignAndValignAndBlockComponent {
    // @ContentChildren(ColComponent) private _colChildren: QueryList<ColComponent>;
    // @ContentChildren(RowComponent) private _rowChildren: QueryList<RowComponent>;

    // indication of containing <ui-col>s (as direct child)
    // @HostBinding('class.internal--with-cols')
    // private get _containsCols() {
    //     return this._colChildren.length > 0;
    // }

    // // indication of containing <ui-row>s (as direct child)
    // @HostBinding('class.internal--with-rows')
    // private get _containsRows() {
    //     return this._rowChildren.length > 0;
    // }

    // [evenSize]
    //     true: evenSize | evenSize="true" | evenSize="evenSize" | evenSize="any value" | [evenSize]="true"
    //     false: no attribute | evenSize="false" | [evenSize]="false" | [evenSize]
    @Input()
    public set evenSize(val: any) {
        const numericValue = parseInt(val, 10);
        // if numeric value, then it divides cols/rows into given count
        if (numericValue.toString() === val.toString()) {
            this._evenSize = numericValue;
        } else {
            this._evenSize = toBoolean(val);
        }
    }
    public get evenSize(): any {
        return this._evenSize;
    }
    /** When evenSize is true, then all inner cols/rows have same size. */
    private _evenSize: boolean | number = false;

    @HostBinding('class.internal--grid')
    private get _evenSizeIsNumeric() {
        return typeof this._evenSize === 'number';
    }

    // gives information of cols/rows count for variation [evenSize]
    // @HostBinding('attr.cell-count')
    // private get _cellCount() {
    //     return this._evenSize ? this._rowChildren.length || this._colChildren.length : undefined;
    // }
}
