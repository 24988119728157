import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import mixpanel from './app/common/mixpanel';

if (environment.production) {
    enableProdMode();
} else {
    const { worker } = require('./mocks/mocks');
    worker.start({ onUnhandledRequest: 'bypass' });
}

mixpanel.init(environment);

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.log(err));

// because same-origin policy (cross iframe javascript comunication)
// will return:
// for "http://localhost:4200/" => "localhost"
// for "https://editor.stylers.cloud/" => "stylers.cloud"
document.domain = location.hostname.split('.').slice(-2).join('.');
