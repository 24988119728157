import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import { ValidationsService } from './../components/validations/validations.service';

@Directive({
    selector: '[scpPasswordMatch]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordMatchDirective, multi: true }],
})
export class PasswordMatchDirective implements Validator {
    @Input() set scpPasswordMatch(otherPassword) {
        this.validatorFn = this.validationsService.passwordMatch(otherPassword);
        if (this.onValidatorChange) {
            this.onValidatorChange();
        }
    }

    validatorFn;
    onValidatorChange;

    constructor(private validationsService: ValidationsService) {}

    registerOnValidatorChange(fn) {
        this.onValidatorChange = fn;
    }

    validate(control: AbstractControl): ValidationErrors {
        return this.validatorFn(control);
    }
}
