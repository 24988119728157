import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    HostListener,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'scp-image-preview',
    templateUrl: './image-preview.component.html',
    styleUrls: ['./image-preview.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class ImagePreviewComponent implements AfterViewInit, OnChanges {
    @Input() url: string;

    @Input()
    caption: string = null;

    @Input()
    @HostBinding('attr.title')
    title = '';

    @Input()
    selectedAreas: SelectedAreaType[] = [];

    @ViewChild('image') private image: ElementRef;

    loaded = false;
    notAvailable = false;

    naturalWidth = 0;
    naturalHeight = 0;
    naturalKoef = 1;

    viewportWidth = 0;
    viewportHeight = 0;
    viewportKoef = 1; // width / height

    zoomKoef = 1;
    offsetLeft = 0;
    offsetTop = 0;

    // in percentage (%)
    selectedAreaMbr = { x1: 0, y1: 0, x2: 100, y2: 100 };

    constructor(private element: ElementRef, private changeDetector: ChangeDetectorRef) {}

    ngAfterViewInit() {
        this.calculateViewportSize();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.imageHash) {
            this.loaded = false;
            this.notAvailable = false;
        }

        if (
            changes.selectedAreas &&
            this.loaded &&
            changes.selectedAreas.currentValue !== changes.selectedAreas.previousValue
        ) {
            this.onImageLoad();
        }
    }

    onImageLoad() {
        this.loaded = true;
        this.naturalWidth = this.image.nativeElement.naturalWidth;
        this.naturalHeight = this.image.nativeElement.naturalHeight;
        this.naturalKoef = this.naturalWidth / this.naturalHeight;
        this.recalculate();
    }

    onImageError() {
        this.notAvailable = true;
    }

    onImageAbort() {}

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.calculateViewportSize();
    }

    calculateViewportSize() {
        this.viewportWidth = this.element.nativeElement.offsetWidth;
        this.viewportHeight = this.element.nativeElement.offsetHeight;
        this.viewportKoef = this.viewportWidth / this.viewportHeight;
        this.recalculate();
    }

    recalculate() {
        if (this.naturalWidth && this.naturalHeight) {
            const padding = 8; // px
            const vw = this.viewportWidth - padding * 2;
            const vh = this.viewportHeight - padding * 2;
            if (this.naturalKoef > this.viewportKoef) {
                this.zoomKoef = vw / this.naturalWidth;
            } else {
                this.zoomKoef = vh / this.naturalHeight;
            }

            this.selectedAreaMbr = { x1: 0, y1: 0, x2: 100, y2: 100 };
            this.offsetLeft = 0;
            this.offsetTop = 0;

            // if selected area
            if (this.selectedAreas && this.selectedAreas[0]) {
                const area = this.selectedAreas[0];
                const areaWidth = this.naturalWidth * (100 / area.width);
                const areaHeight = this.naturalHeight * (100 / area.height);
                const areaKoef = areaWidth / areaHeight;

                let zoom;
                if (areaKoef < this.viewportKoef) {
                    zoom = 100 / area.width;
                } else {
                    zoom = 100 / area.height;
                }

                if (areaKoef < this.viewportKoef) {
                    this.zoomKoef = (zoom * vw) / this.naturalWidth;
                } else {
                    this.zoomKoef = (zoom * vh) / this.naturalHeight;
                }

                // relative from center
                const centerOfAreaX = (area.left + area.width / 2) / 100;
                const centerOfAreaY = (area.top + area.height / 2) / 100;
                this.offsetLeft = this.zoomKoef * this.naturalWidth * (0.5 - centerOfAreaX);
                this.offsetTop = this.zoomKoef * this.naturalHeight * (0.5 - centerOfAreaY);

                // in percentage (%)
                this.selectedAreaMbr = {
                    x1: area.left,
                    y1: area.top,
                    x2: area.left + area.width,
                    y2: area.top + area.height,
                };
            }
            // console.log(this.zoomKoef);
            // console.log(this.selectedAreas);
        }
    }
}

export type SelectedAreaType = {
    left: number;
    top: number;
    width: number;
    height: number;
};
