import { MessagingComponent } from './messaging.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageListComponent } from './message-list/message-list.component';
import { FormsModule } from '@angular/forms';
import { MessageFormComponent } from './message-form/message-form.component';
import { MessageItemComponent } from './message-item/message-item.component';
import { TextFieldModule } from '@angular/cdk/text-field';

const COMPONENTS = [MessageListComponent, MessageFormComponent, MessagingComponent, MessageItemComponent];

@NgModule({
    declarations: [...COMPONENTS],
    exports: [...COMPONENTS],
    imports: [CommonModule, FormsModule, TextFieldModule],
})
export class MessagingModule {}
