import { DurationPipe } from './pipes/duration.pipe';

import { NgModule } from '@angular/core';
import { CurrencyCentsPipe } from './pipes/currency-cents.pipe';
import { WageStringPipe } from './pipes/wageString.pipe';

const components = [DurationPipe, CurrencyCentsPipe, WageStringPipe];

@NgModule({
    declarations: [...components],
    exports: [...components],
    providers: [],
})
export class CommonPipesModule {}
