import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private router: Router) {}

    addAuthHeader(request: HttpRequest<any>) {
        const accessToken = this.auth.getAccessToken();
        return request.clone({
            headers: request.headers.set('Authorization', `Bearer ${accessToken}`),
        });
    }

    handleResponseError(error: any, request: HttpRequest<any>, next: HttpHandler) {
        if (error.status === 401) {
            const isAlreadyRefreshTokenCall = error.url && error.url.indexOf('/auth/refresh') > -1;
            if (!isAlreadyRefreshTokenCall) {
                const refreshToken = localStorage.getItem('refreshToken');
                return this.auth.refreshAccessToken(refreshToken).pipe(
                    switchMap(() => {
                        request = this.addAuthHeader(request);
                        return next.handle(request);
                    }),
                    catchError((e: HttpErrorResponse) => {
                        if (e.status == 401) {
                            console.log('logging out!');
                            this.auth.logOut();
                        }

                        return throwError(e);
                    }),
                );
            } else {
                this.auth.logOutLocal();
                this.router.navigate(['new/login']);
                return throwError(error);
            }
        }

        return throwError(error);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        if (request.headers.get('skipInterceptors') !== null) {
            const newRequest = request.clone({ headers: request.headers.delete('skipInterceptors') });
            return next.handle(newRequest).pipe(
                catchError(error => {
                    return this.handleResponseError(error, request, next);
                }),
            );
        }

        request = this.addAuthHeader(request);
        return next.handle(request).pipe(
            catchError(error => {
                return this.handleResponseError(error, request, next);
            }),
        );
    }
}
