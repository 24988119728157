import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';

@Component({
    selector: 'scp-ui-pagination',
    templateUrl: 'pagination.component.html',
    styleUrls: ['./pagination.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginationComponent implements OnInit {
    @Input() pagination: TablePagination;
    @Output() pageChange = new EventEmitter<number>();

    constructor() {}

    ngOnInit() {}

    onNextPageBtnClick() {
        this.pageChange.emit(this.pagination.pageNumber + 1);
    }

    onPrevPageBtnClick() {
        this.pageChange.emit(this.pagination.pageNumber - 1);
    }

    getMaxPages() {
        return Math.ceil(this.pagination.totalCount / this.pagination.pageSize);
    }
}

export interface TablePagination {
    totalCount: number;
    pageNumber: number;
    pageSize: number;
}
