import { NewMessage } from './../messaging.types';
import {
    Component,
    Input,
    Output,
    ViewEncapsulation,
    EventEmitter,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';

@Component({
    selector: 'scp-message-form',
    templateUrl: './message-form.component.html',
    styleUrls: ['./message-form.component.sass'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageFormComponent implements OnChanges {
    @Input() message: NewMessage;
    @Output() messageChange = new EventEmitter<NewMessage>();
    @Output() send = new EventEmitter<NewMessage>();
    @ViewChild('submit') button: ElementRef;
    @ViewChild('autosize', { read: CdkTextareaAutosize }) autosize: CdkTextareaAutosize;

    public text = '';
    public previousSentText = '';

    public ngOnInit(): void {
        // refresh size on init (fix of firefox misfunction)
        [100, 250, 500, 1000].forEach(delay => {
            setTimeout(() => {
                this.autosize?.resizeToFitContent(true);
            }, delay);
        });
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.message && changes.message.currentValue !== changes.message.previousValue) {
            this.text = this.message?.text;
            this.autosize?.reset();
            if (this.previousSentText !== this.text) {
                this.previousSentText = null;
            }
        }
    }

    public sendMessage(): void {
        // blur button to cancel outline (focus) after click
        this.button.nativeElement.blur();

        if (this.text == null || this.text.trim() === '' || this.previousSentText === this.text) {
            return;
        }

        const message = {
            ...this.message,
            text: this.text,
        };
        this.previousSentText = this.text;
        this.messageChange.emit(message);
        this.send.emit(message);
    }
}
