<ui-env></ui-env>
<mat-toolbar color="primary" class="overflow-hidden">
    <div class="left-side">
        <mat-nav-list>
            <mat-list-item>
                <a mat-flat-button color="primary" [routerLink]="['/']"
                    ><img class="logo" src="assets/icon/favicon.png" alt="" />
                    <span>Stylers</span>
                </a>
            </mat-list-item>
            <mat-list-item *ngFor="let menuItem of visibleMenuItems">
                <a
                    mat-flat-button
                    color="primary"
                    *ngIf="!menuItem.children; else viewsMobile"
                    [routerLink]="menuItem.url"
                    routerLinkActive="active"
                >
                    <span mat-line>
                        {{ menuItem.title }}
                    </span>
                </a>

                <ng-template #viewsMobile>
                    <a
                        class="nav-link"
                        *ngFor="let child of menuItem.children"
                        [routerLink]="child.url"
                        routerLinkActive="active"
                    >
                        {{ child.title }}
                    </a>
                </ng-template>
            </mat-list-item>
        </mat-nav-list>
    </div>
    <div class="right-side">
        <ng-content select="[additional-buttons]"></ng-content>
        <div class="me-4 d-flex align-items-center" style="font-size: 14px; cursor: pointer" routerLink="new/profile">
            <span>{{ (currentUser$ | async)?.nickname }}</span
            >&nbsp;
            <mat-icon>person_outline</mat-icon>
        </div>
        <a
            mat-flat-button
            color="primary"
            title="Logout"
            (click)="logout()"
            *ngIf="logoutButton"
            class="align-baseline"
        >
            Logout

            <mat-icon> logout </mat-icon>
        </a>
    </div>
</mat-toolbar>
