import { User } from './user.types';

type GenericObject = { [key: string]: any };

export interface TaskDetail<ProjectExtras = GenericObject> extends Task {
    id: string;
    tokenizedHtmlHashes: string[];
    internalDescription: string;
    managedByUser?: Omit<User, 'email'>;
    assignedUser?: Omit<User, 'email'>;
    codeReviewedByUser?: Omit<User, 'email'>;
    // task is marked only for testing purpose, not production task
    testing: boolean;
    sass?: string;
    realEffort?: number;
    project: {
        id: string;
    } & ProjectExtras;

    wage?: number;
    paidOutDateTime?: string;
    paidOutWage?: number;
    /** If there is an unreviewed stylers turn. */
    reviewPending?: boolean;
}

export interface ListTask
    extends Omit<
        TaskDetail,
        | 'tokenizedHtmlHashes'
        | 'internalDescription'
        | 'htmlIds'
        | 'htmls'
        | 'selectors'
        | 'elements'
        | 'emotion'
        | 'externalDesignUrls'
        | 'internalDesigns'
        | 'context'
    > {
    lastModifiedDatetime: string;
}

export interface NewTask
    extends Pick<
        Task,
        | 'htmlIds'
        | 'selectors'
        | 'elements'
        | 'type'
        | 'urgency'
        | 'description'
        | 'emotion'
        | 'externalDesignUrls'
        | 'internalDesigns'
        | 'parentTask'
        | 'context'
        | 'standalone'
    > {
    name?: string;
    managedByUser?: {
        id: User['id'];
    };
}

export type TaskTreeItem = Pick<
    TaskDetail,
    'id' | 'name' | 'type' | 'state' | 'compositeState' | 'urgency' | 'createdDatetime' | 'parentTask'
> & {
    hasTaskManager: boolean;
    hasStyler: boolean;
    hasCodeReviewer: boolean;
};

export interface Task {
    name: string;
    htmlIds: string[];
    htmls: TaskHtml[];
    selectors?: string[];
    elements?: TaskElement[];
    type?: TaskType;
    state: TaskState;
    compositeState: TaskState;
    urgency: Urgency;
    description?: string;
    createdBy: User;
    emotion?: TaskEmotion;
    emotionText?: string;
    createdDatetime: string;
    deadlineDatetime: string;
    deliveredDatetime: string;
    deadlineDuration?: number; // calculated
    difficulty?: Difficulity;
    externalDesignUrls: string[];
    internalDesigns: InternalDesign[];
    subtasksCount: number;
    parentTask?: {
        id: string;
    };
    context: PageContext;
    url?: string;
    html?: Pick<TaskHtml, 'id'>;
    standalone: boolean;
    lastTotalBilledTime: number;
    lastDiscountPercentage: number;
}

export type Difficulity = 'low' | 'standard' | 'high' | 'extreme';

export interface TaskHtml {
    id: string;
    originalUrl: string;
    tokenizedHash: string;
    sitemap: boolean;
    sitemapName: string;
    createdDatetime: string;
}

export interface TaskHtmlDetail extends TaskHtml {
    task: { id: string; name: string };
}

export interface HtmlSource {
    id?: string;
    state: TaskState;
    html: TaskHtml;
    htmlLabel: string;
    sitemap: boolean;
    createdDatetime: string;
}

export enum Urgency {
    low = 'low',
    normal = 'normal',
    urgent = 'urgent',
    asap = 'asap',
}

export enum UrgencyDiscount {
    low = -20,
    normal = 0,
    urgent = 50,
    asap = 100,
}

export enum TaskState {
    draft = 'draft',
    new = 'new',
    amended = 'amended',
    analyzing = 'analyzing',
    unclear = 'unclear',
    queued = 'queued',
    checking = 'checking',
    revalidate = 'revalidate',
    inProgress = 'in progress',
    forReview = 'for review',
    reviewing = 'reviewing',
    disapproved = 'disapproved',
    reviewed = 'reviewed',
    completing = 'completing',
    refused = 'refused',
    done = 'done',
    accepted = 'accepted',
    rejected = 'rejected',
    canceled = 'canceled',
    blocked = 'blocked', // only for compositeState
}

export enum AttributeDifficulty {
    low = 'low',
    standard = 'standard',
    high = 'high',
    extreme = 'extreme',
}

export interface InternalDesign {
    designId: string;
    selectedAreas: {
        left: number;
        top: number;
        width: number;
        height: number;
    }[];
}

export interface PageContext {
    window: {
        width: number;
        height: number;
        scrollLeft: number;
        scrollTop: number;
    };
    browser: {
        userAgent: string;
        appName: string;
        appVersion: string;
        platform: string;
    };
    device: {
        colorDepth: number;
        pixelRatio: number;
    };
}

export enum TaskType {
    task = 'task',
    issue = 'issue',
}

export interface TaskElement {
    selector: string;
    exactSelector: string;
    deeperSelector?: string | null;
    mbr: {
        left: number;
        top: number;
        width: number;
        height: number;
    };
    offset: {
        left: number;
        top: number;
        exactSelector: string;
    };
    pin?: {
        left: number;
        top: number;
    };
    description: string;
}

export interface TaskPin {
    exactSelector: string;
    left: number;
    top: number;
}

export enum TaskEmotion {
    loving = 'loving',
    ok = 'ok',
    neutral = 'neutral',
    worried = 'worried',
    panicked = 'panicked',
    angry = 'angry',
    resigned = 'resigned',
}

export interface TaskListFilter {
    type?: TaskType[];
    testing?: '0' | '1';
    state?: TaskState[];
    managedByUser?: string;
    assignedUser?: string;
    todoCodeReviewer?: boolean;
    todoTaskManager?: boolean;
    supportProject?: '0' | '1';
    standalone?: '0' | '1';
    todoStyler?: boolean;
    relatedToMe?: string;
    project?: string;
}

export interface TaskStateHistory extends TotalBilledTaskStateExtension {
    context: PageContext;
    createdBy: User;
    createdDatetime: string;
    elements?: TaskElement[];
    html: TaskHtml;
    id: string;
    masterCommitId: string;
    state: TaskState;
    task: Pick<TaskHtml, 'id'>;
    taskCommitId: string;
    afterMergeTaskCommitId: string;
    taskCommitIdBeforeConflict: string | null;
    /** State duration in seconds.  */
    duration?: null | number;
    /** This is not comming from API, it is postprocessed from next state change (createdBy). */
    worker?: User;
    /** Logged work in seconds.  */
    realEffort?: null | number;
    realStandardEffort?: null | number;
    discountPercentage?: null | number;
    platformActivityDuration?: number;
}

interface TotalBilledTaskStateExtension {
    /** Standard hours (in seconds) that will be charged to the customer for the task (incl. subtasks). */
    totalBilledTime?: null | number;
    /** Total task price (in EUR cents) that will be charged to the customer for the task (incl. subtasks).  */
    totalBilledPrice: number;
}

export interface SubtaskItem extends TaskTreeItem, TotalBilledTaskStateExtension {
    /** Flag if `totalBilledTime` and `totalBilledPrice` is real value or estimated. */
    totalIsLegit: boolean;
}

// type=task|issue (multiple values),
// testing=0|1 (single value),
// state (multiple values),
// managedByUser (single UUID or null),
// assignedUser (single UUID or null)
