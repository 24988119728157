import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    selector: 'scp-badge-pill',
    templateUrl: './badge-pill.component.html',
    styleUrls: ['./badge-pill.component.sass'],
    encapsulation: ViewEncapsulation.None,
})
export class BadgePillComponent {
    constructor() {}
}
