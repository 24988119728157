import { Component, HostBinding, Input } from '@angular/core';

/**
 * Reusable functionality with [align] and [valign] attributes.
 * Used by <ui-layout>, <ui-col> and <ui-row>.
 */
@Component({
    template: '',
})
export class GenericAlignAndValignAndBlockComponent {
    /* tslint:disable-next-line:no-input-rename */
    @Input('align')
    /** Horizontal align of layout content. Accepted values: left | center | right | justify. */
    private _horizontalAlign: UiHorizontalAlign = null; // default (when null) is "left"

    // generate class names by valign value
    @HostBinding('class.internal--align-left')
    private get _isHorizontalAlignLeft() {
        return this._horizontalAlign === 'left';
    }
    @HostBinding('class.internal--align-center')
    private get _isHorizontalAlignCenter() {
        return this._horizontalAlign === 'center';
    }
    @HostBinding('class.internal--align-right')
    private get _isHorizontalAlignRight() {
        return this._horizontalAlign === 'right';
    }
    @HostBinding('class.internal--align-justify')
    private get _isHorizontalAlignJustify() {
        return this._horizontalAlign === 'justify';
    }

    /* tslint:disable-next-line:no-input-rename */
    @Input('valign')
    /** Vertical align of layout content. Accepted values: top | center | bottom. */
    private _verticalAlign: UiVerticalAlign = null; // default (when null) is "top"

    // generate class names by valign value
    @HostBinding('class.internal--valign-top')
    private get _isVerticalAlignTop() {
        return this._verticalAlign === 'top';
    }
    @HostBinding('class.internal--valign-center')
    private get _isVerticalAlignCenter() {
        return this._verticalAlign === 'center';
    }
    @HostBinding('class.internal--valign-bottom')
    private get _isVerticalAlignBottom() {
        return this._verticalAlign === 'bottom';
    }

    /* tslint:disable-next-line:no-input-rename */
    @Input('justify')
    /** Vertical align of items. Accepted values: top | center | bottom | stretch. */
    private _itemsAlign: UiJustifyAlign = null; // default (when null) is "top"

    // generate class names by justify value
    @HostBinding('class.internal--justify-top')
    private get _isJustifyAlignTop() {
        return this._itemsAlign === 'top';
    }
    @HostBinding('class.internal--justify-center')
    private get _isJustifyAlignCenter() {
        return this._itemsAlign === 'center';
    }
    @HostBinding('class.internal--justify-bottom')
    private get _isJustifyAlignBottom() {
        return this._itemsAlign === 'bottom';
    }
    @HostBinding('class.internal--justify-stretch')
    private get _isJustifyAlignStretch() {
        return this._itemsAlign === 'stretch';
    }

    // tslint:disable-next-line:no-input-rename
    @Input('block') private _block: any = undefined;
    @HostBinding('class.ui-flex')
    private get _isNotBlock() {
        return this._block === undefined;
    }
}

export type UiHorizontalAlign = null | 'left' | 'center' | 'right' | 'justify';
export type UiVerticalAlign = null | 'top' | 'center' | 'bottom';
export type UiJustifyAlign = null | 'top' | 'center' | 'bottom' | 'stretch';
