import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'localeDate',
})
export class LocaleDatePipe implements PipeTransform {
    transform(value: any, leadingZeros = false): any {
        // TODO if different format is needed for different locals
        const format = leadingZeros ? 'DD.MM.YYYY' : 'D.M.YYYY';
        if (value) {
            return moment(value).format(format);
        } else {
            return '';
        }
    }
}
