import mixpanel, { Callback, Dict, RequestOptions } from 'mixpanel-browser';
import { UserDetail } from '../../../shared/types/user.types';

function track(
    event_name: string,
    properties?: Dict,
    optionsOrCallback?: RequestOptions | Callback,
    callback?: Callback,
): void {
    try {
        mixpanel.track(event_name, properties, optionsOrCallback, callback);
    } catch (e) {
        console.error(e);
    }
}

function time_event(event_name: string): void {
    try {
        mixpanel.time_event(event_name);
    } catch (e) {
        console.error(e);
    }
}

function init(environment: { production: boolean }): void {
    try {
        mixpanel.init('a68aa549899906b620cc72c4aa04efb1', { debug: !environment.production });
        mixpanel.register_once({
            sc_app: 'platform',
        });
        mixpanel.track('init');
    } catch (e) {
        console.log(e);
    }
}

function setUserInfo(userDetail: UserDetail): void {
    try {
        if (userDetail) {
            const peopleInfo = {
                createdDatetime: userDetail.createdDatetime,
                nickname: userDetail.nickname,
                $email: userDetail.email,
                $name: userDetail.nickname,
                roles: userDetail.roles,
                subroles: userDetail.subroles,
            };
            mixpanel.alias(userDetail.email, userDetail.id);
            mixpanel.people.set(peopleInfo);
        }
    } catch (e) {
        console.error(e);
    }
}

function identifyUser(user: { id?: string }): void {
    try {
        if (user.id) {
            mixpanel.identify(user.id);
        }
    } catch (e) {
        console.error(e);
    }
}

function reset() {
    mixpanel.reset();
}

export default {
    track,
    time_event,
    init,
    setUserInfo,
    identifyUser,
    reset,
};
