<!-- <scp-ui-loader
    *ngIf="!loaded && (!notAvailable || imageHash===null)"
    class="centered"
></scp-ui-loader> -->
<div
    class="image"
    [class.visible]="url && loaded"
    [style.width.px]="naturalWidth * zoomKoef"
    [style.height.px]="naturalHeight * zoomKoef"
    [style.margin-left.px]="offsetLeft"
    [style.margin-top.px]="offsetTop"
>
    <img
        #image
        [src]="url | getSrc"
        (load)="onImageLoad()"
        (error)="onImageError()"
        (abort)="onImageAbort()"
        ondragstart="return false"
    />
    <div
        class="overlay-outline"
        [style.left.%]="selectedAreaMbr.x1"
        [style.top.%]="selectedAreaMbr.y1"
        [style.right.%]="100 - selectedAreaMbr.x2"
        [style.bottom.%]="100 - selectedAreaMbr.y2"
    ></div>
</div>
<div class="caption">{{ caption }}</div>

<p *ngIf="notAvailable && url !== null" class="secondary small">Preview is not available {{ notAvailable }}</p>
