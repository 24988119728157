import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class DateService {
    constructor(private datePipe: DatePipe) {}

    formatDate(timestamp: number, format = 'medium') {
        return this.datePipe.transform(timestamp, format);
    }
}
