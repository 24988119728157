import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'toTime',
})
export class ToTimePipe implements PipeTransform {
    constructor() {}

    /**
     * Convert to human readable time.
     * @param dateStr Date string in ISO format from JSON api, e.g. "2020-10-19T13:28:06.419Z"
     */
    transform(dateStr: string): string {
        const date = new Date(dateStr);
        const hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() + '';
        const minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() + '';
        return hours + ':' + minutes;
    }
}
