import { OverlayDirective } from './directives/overlay.directive';
import { MatDialogModule } from '@angular/material/dialog';
import { SemDateAdapter } from './sem-date-adapter';
import { RouterModule } from '@angular/router';
import { PasswordMatchDirective } from './directives/password-match.directive';
import { LocaleDatePipe } from './directives/localeDate.pipe';
import { ValidationsComponent } from './components/validations/validations.component';
import { GlobalLoadingIndicatorComponent } from './components/global-loading-indicator/global-loading-indicator.component';
import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS } from '@angular/material-moment-adapter';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertComponent } from './alerts/alert/alert.component';
import { GetSrcPipe } from './pipes/getSrc.pipe';
import { ToDaysPipes } from './pipes/toDays.pipe';
import { ToTimePipe } from './pipes/toTime.pipe';
import { BooleanPipe } from './pipes/boolean.pipe';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { MatButtonModule } from '@angular/material/button';
import { CommonPipesModule } from './common-pipes.module';
import { HtmlPreviewComponent } from './components/html-preview/html-preview.component';
import { ImagePreviewComponent } from './components/image-preview/image-preview.component';
import { CallMethodPipe, CallFunctionPipe } from './pipes/pureCall.pipe';
import { MixpanelModule } from './directives/mixpanel.module';
// import { PrismComponent } from './prism/prism.component';

const components = [
    GlobalLoadingIndicatorComponent,
    ValidationsComponent,
    LocaleDatePipe,
    PasswordMatchDirective,
    AlertsComponent,
    AlertComponent,
    OverlayDirective,
    GetSrcPipe,
    ToDaysPipes,
    ToTimePipe,
    BooleanPipe,
    CallFunctionPipe,
    CallMethodPipe,
    PageNotFoundComponent,
    HtmlPreviewComponent,
    ImagePreviewComponent,
    // PrismComponent,
];

const modules = [
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule,
    CommonPipesModule,
    MixpanelModule,
];

@NgModule({
    imports: [CommonModule, RouterModule, ...modules],
    declarations: [...components],
    exports: [...components, ...modules],
    providers: [
        DatePipe,
        { provide: DateAdapter, useClass: SemDateAdapter },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
    ],
})
export class UiCommonModule {}
